import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { History } from 'history';import rootReducer from './reducers/rootReducer';
import { RootState } from '../types/account';
import { createBrowserHistory } from 'history';

const enhancers:any = [];
const windowIfDefined = typeof window === 'undefined' ? null : (window as any);

if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

export default function configureStore(history: History, initialState?: RootState) {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducerWithRouter = combineReducers({
        ...rootReducer,
        router: connectRouter(history),
    });

    return createStore(rootReducerWithRouter, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}

export const history = createBrowserHistory();
export const store = configureStore(history);
export type AppDispatch = typeof store.dispatch;

