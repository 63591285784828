const Icon = ({
  path = '',
  color = 'inherit',
  className = '',
  style = {},
  size = 24,
  onClick = () => {},
  fullWidth = false,
}) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={fullWidth ? "" : `0 0 ${size + 6} ${size + 6}`}
      width={size}
      height={size}
      fill={'currentColor'}
      color={color}
      className={className}
    >
      <path d={`${path}`} />
    </svg>
  );
};
export { Icon };
