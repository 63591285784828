import { Reducer } from 'redux';
import { NotificationActions } from '../actions/notificationActions';
import { NotificationState } from '../../types/notification';

const initialState: NotificationState = {
  type: 'success',
  paragraph: { title: '', content: '' },
  title: '',
  description: '',
};

const notificationReducer: Reducer<NotificationState, any> = (
  currentState: NotificationState | undefined,
  incomingAction: NotificationActions
): NotificationState => {
  if (!currentState) currentState = initialState;

  if (incomingAction.type === 'notify/setTitle') {
    return {
      ...currentState,
      title: incomingAction.title,
    };
  }

  if (incomingAction.type === 'notify/setDescription') {
    return { ...currentState, description: incomingAction.description };
  }
  if (incomingAction.type === 'notify/setType') {
    return { ...currentState, type: incomingAction.notificationType };
  }
  if (incomingAction.type === 'notify/addParagraph') {
    return { ...currentState, paragraph: incomingAction.paragraph };
  }
  if (incomingAction.type === 'notify/deleteParagraph') {
    return { ...currentState, paragraph: { title: '', content: '' } };
  }

  return currentState || initialState;
};

export default notificationReducer;
