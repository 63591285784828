/* eslint-disable */
import { mdiCog } from '@mdi/js';
import { Link } from 'react-router-dom';
import AdminLinks from '../AdminLinks/AdminLinks';
import DropdownCollapse from '../DropdownCollapse/DropdownCollapse';
import { Icon } from '../Icon';
import './Navbar.css';
import { Roles } from '../../types/users';
interface NavbarProps {
  account: { isAuthenticated: boolean; account: { role: string } };
  className?: string;
}
const Navbar = ({ account, className }: React.PropsWithChildren<NavbarProps>) => {
  return (
    <nav className={`HeaderNav ${className ? className : ''} shadow`}>
      <div className="HeaderNav--list">
        <Link className={'HeaderNav--item HeaderNav--link'} to="/sitesmanagement">
          <span>
            <Icon path={mdiCog} size={24}></Icon>
          </span>{' '}
          <span>SITES MANAGEMENT</span>
        </Link>
        {account.account.role.toUpperCase() === Roles.ADMIN && (
          <DropdownCollapse className={'HeaderNav--item'} title={'ADMIN SETTINGS'}>
            <AdminLinks />
          </DropdownCollapse>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
