import { LoginModel } from '../types/account';

export const login =  (account: LoginModel) =>
    fetch( window.location.origin + '/api/Account/SignIn', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(account)
    });

export const logout = () =>
    fetch( window.location.origin + '/api/Account/Logout', {
        method: 'POST'
    });
    