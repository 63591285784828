export type User = {
    id: string,
    firstName: string,
    lastName: string,
    infusionSoftOrderId: number,
    countSites: number,
    email: string,
    newEmail: string,
    emailConfirmed: boolean,
    phoneNumber: string,
    address: string,
    city: string,
    country: string,
    dmpLogin: string,
    dmpPassword: string,
    dmpToken: string,
    state: string,
    zip: string,
    creiPassword:string,
    creiLogin: string,
    newPassword: string,
    role: string,
}

export interface UsersState {
    users: User[];
}

export enum Roles {
    ADMIN = 'ADMIN',
    USER = 'USER',
}