import { toast } from 'react-toastify';
import { authFetch, updateFetch } from '../myFetches';

const AwsMonitoring = {
  getInstancesByPage: (
    SearchString: string | undefined,
    SortOrder: string | undefined,
    SortType: string | undefined,
    PageNumber: number,
    NumberOfInstances: number,
    statusCodeHandle?: (status: number) => {}
  ) =>
    authFetch(window.location.origin + '/api/Admin/awsMonitor/getInstancesByPage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        SearchString: SearchString,
        SortOrder: SortOrder,
        SortType: SortType,
        PageNumber: PageNumber,
        NumberOfRecord: NumberOfInstances,
      }),
    }).then(async response => {
      try {
        if (statusCodeHandle) {
          statusCodeHandle(response.status);
        }
        if (response.status !== 200) {
          return;
        }
        const result = await response.json();
        if (result.isSuccessful && result.data && result.data.Instances) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
        } else {
          return 0;
        }
      } catch (e) {
        console.log('Error. getInstancesByPage: ' + e);
        toast.error('Unexpected error');
      }
    }),

  getSitesOnInstanceByPage: (
    InstanceId: string,
    SearchString: string | undefined,
    SortOrder: string | undefined,
    SortType: string | undefined,
    PageNumber: number,
    NumberOfInstances: number,
    statusCodeHandle?: (status: number) => {}
  ) =>
    authFetch(window.location.origin + '/api/Admin/awsMonitor/getSitesOnInstanceByPage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        InstanceId: InstanceId,
        SearchString: SearchString,
        SortOrder: SortOrder,
        SortType: SortType,
        PageNumber: PageNumber,
        NumberOfRecord: NumberOfInstances,
      }),
    }).then(async response => {
      if (statusCodeHandle) {
        statusCodeHandle(response.status);
      }
      if (response.status !== 200) {
        return;
      }
      try {
        const result = await response.json();
        if (result.isSuccessful && result.data && result.data.SitesOnInstance) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
        } else {
          return 0;
        }
      } catch (e) {
        console.log('Error. getSitesOnInstanceByPage: ' + e);
        toast.error('Unexpected error');
      }
    }),

  launchInstace: (capacity: string | number, cb: any, statusCodeHandle?: (status: number) => {}) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/awsMonitor/launchEC2Instance',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ Capacity: capacity }),
      },
      toastTitle: `Instance is creating`,
      toastSuccess: 'Instance was created',
      callback: cb,
      statusCodeHandle,
    });
  },

  terminateInstance: async (cb: () => {}, id?: string | number, statusCodeHandle?: (status: number) => {}) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/awsMonitor/terminateInstance',
      options: {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ InstanseId: id }),
      },
      toastTitle: `Terminating instance`,
      toastSuccess: 'Instance was terminated',
      callback: cb,
      statusCodeHandle,
    });
  },
};

export default AwsMonitoring;
