import { toast } from 'react-toastify';
const updateFetch = async ({ link = '', options = {}, toastTitle = '', toastSuccess = '', callback = () => {}, statusCodeHandle = (status: number) => {}, isBigMessage=false }) => {
  let createUserNotification = toast.loading(toastTitle, { autoClose: false, position: isBigMessage ? "top-center" : "top-right",  bodyClassName: isBigMessage ? "toastify--big-message" : "" });
  const update = (status: boolean, error: string) =>
    toast.update(createUserNotification, {
      position: isBigMessage ? "top-center" : "top-right",
      render: status ? toastSuccess : error,
      type: status ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      autoClose: 5000,
      isLoading: false,
      bodyClassName: isBigMessage ? "toastify--big-message" : ""
    });

  try {
    const req = await authFetch(link, options);
    statusCodeHandle && statusCodeHandle(req.status);
    if (req.status !== 200){
      return;
    }
    const res = await req.json();
    if (res.isSuccessful) {
      update(true, '');
    } else {
      update(false, res.errors[0]);
    }
  } catch (e) {
    const linkRoutes = link.split('/');
    console.log(`Error. ${linkRoutes[linkRoutes.length - 1]}: ` + e);
    update(false, 'Unexpected error');
  } finally {
    callback();
  }
};
const authFetch = (req: string, options?: { headers?: any; method?: string; body?: any }) => {
  const token = localStorage.creimsToken;
  if (options) {
    if (options.headers) {
      options.headers = { ...options.headers, Authorization: 'Bearer ' + token };
    } else options.headers = { Authorization: 'Bearer ' + token };
    if (!options.method || !options.method.trim()) options.method = 'GET';
    if (!options.body) options.body = '';
    return fetch(req, options);
  }
  return fetch(req, { headers: { Authorization: 'Bearer ' + token } });
};

const flexRequest = async (request: Promise<Response>, failCb: (error: string) => {}) => {
  try {
    const req = await request;
    if (req.status === 200) {
      const result = await req.json();
      if (result.isSuccessful) {
        return { status: true, data: result.data };
      }

      for (const error of result.errors) {
        failCb(error);
      }
    } else {
      failCb('Something went wrong');
      return { status: false, data: null };
    }
  } catch (e) {
    failCb('Something went wrong');
    console.log(e);
    return { status: false, data: null };
  }
};

const tokensIsExisted = async (
  cbSuccess?: () => any,
  cbFail?: (error: string) => void,
  load?: { loading?: () => {}; loaded?: () => {} },
  statusCodeHandle?: (status: number) => {}
) => {
  load && load.loading && load.loading();

  try {
    cbSuccess && cbSuccess();
    return
    // const req = await authFetch(window.location.origin + '/api/Admin/isHasISTokenAndMappedFields');
    // if (statusCodeHandle) {
    //   statusCodeHandle(req.status);
    // }
    // if (req.status !== 200) {
    //   return;
    // }
    // const res = await req.json();
    // if (res.isSuccessful) {
    //   if (res.data.IsHasToken && res.data.IsMappedFields) {
    //     cbSuccess && cbSuccess();
    //   } else {
    //     if (!res.data.IsHasToken && res.data.IsMappedFields) {
    //       cbFail && cbFail('No access to Infusionsoft tokens');
    //       return;
    //     }
    //     if (res.data.IsHasToken && !res.data.IsMappedFields) {
    //       cbFail && cbFail('Infusionsoft custom fields not mapped');
    //       return;
    //     }
    //     if (!res.data.IsHasToken && !res.data.IsMappedFields) {
    //       cbFail && cbFail('No access to Infusionsoft tokens and Infusionsoft custom fields not mapped');
    //       return;
    //     }
    //   }
    // } else {
    //   res.errors.forEach((error: string) => {
    //     cbFail && cbFail(error);
    //   });
    // }
  } catch (e) {
    cbFail && cbFail('Something went wrong');
    console.log(e);
  } finally {
    load && load.loaded && load.loaded();
  }
};


export { updateFetch, authFetch, tokensIsExisted, flexRequest };
