import { mdiPower } from '@mdi/js';
import { Link } from 'react-router-dom';
import DropdownCollapse from '../DropdownCollapse/DropdownCollapse';
import { Icon } from '../Icon';
import './Header.css';

type NavbarProps = {
  isAuthenticated: boolean;
  username: string;
  logout?: () => void;
};

export const Header = function (mainProps: NavbarProps) {
  return (
    <>
      {mainProps.isAuthenticated && (
        <div className="Header navbar navbar-expand navbar-light bg-white static-top shadow">
          <div className="Header-title">
            <img src="assets/images/header.png" alt="header" className="Img-Header" />
          </div>
          <div>
            <DropdownCollapse withArrow title={mainProps.username}>
              <ul className="Header-menu">
                <li className="Header-menu-item">
                  <Link
                    className="Link"
                    to="/login"
                    onClick={e => {
                      e.preventDefault();
                      mainProps.logout && mainProps.logout();
                    }}
                  >
                    <span>
                      <Icon size={20} path={mdiPower} />
                    </span>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </DropdownCollapse>
          </div>
        </div>
      )}
    </>
  );
};
