import { mdiChevronDown } from '@mdi/js';
import React, { useEffect, useState } from 'react';
import { Icon } from '../Icon';
import './DropdownCollapse.css';

interface DropdownCollapseProps {
  title: string | JSX.Element;
  className?: string;
  style?: object;
  icon?: JSX.Element | string;
  children?: JSX.Element | string;
  withArrow?: boolean;
}
const DropdownCollapse = function ({
  title,
  className,
  style,
  icon,
  children,
  withArrow = false,
}: React.PropsWithChildren<DropdownCollapseProps>) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isBodyOpen, setIsBodyOpen] = useState<boolean>(false);
  useEffect(() => {
    isHover ? setTimeout(() => setIsBodyOpen(true), 50) : setTimeout(() => setIsBodyOpen(false), 50);
  }, [isHover]);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={`DropdownCollapse ${className || ''}`}
      style={style}
    >
      <div className="DropdownCollapse--header">
        <span className="DropdownCollapse--title-icon">{icon}</span>
        <span className="DropdownCollapse--title">{title}</span>
        <span className="DropdownCollapse--title-icon-down">{withArrow && <Icon path={mdiChevronDown} />}</span>
      </div>
      {isHover && <div className={`DropdownCollapse--body ${isBodyOpen ? '--open' : ''}`}>{children}</div>}
    </div>
  );
};

export default DropdownCollapse;
