import { useState } from 'react';
import accountThunks from '../app/thunks/accountThunks';

import { connect } from 'react-redux';
import FullLoader from './Loader/FullLoader/FullLoader';

interface LoginProps {
  login: typeof accountThunks.login;
}

const Login = ({ login }: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const handleChange = (e: any) => {
    const { name, value } = e.target as HTMLInputElement;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    login({ email, password }, {load: ()=>setLoading(true), loaded: ()=>setLoading(false)});
  };

  return (
    <main className="d-flex align-items-center min-vh-100 py-3 py-md-0">
      <div className="">
        <div
          className="toast align-items-center text-white bg-primary border-0"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">Hello, world! This is a toast message.</div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="login-card">
          <div className="row no-gutters">
            <div className="col-md-5 nopadding">
              <img src="assets/images/login.png" alt="login" className="login-card-img" />
            </div>
            <div className="col-md-7 nopadding position-relative">
              <div className="card-body LoginPage--form">
                <p className="login-card-description">Sign In</p>
                <form>
                  <div className="mb-3">
                    <div className="form-group">
                      <label className="pt-2">Enter email:</label>
                      <br />
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Email address"
                        value={email}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group mb-4">
                      <label className="pt-2">Enter password:</label>
                      <br />
                      <input
                        id="password"
                        name="password"
                        type="password"
                        className="form-control form-control-lg"
                        autoComplete="off"
                        placeholder="***********"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="d-grid gap-2 col-13 mx-auto">
                      <button
                        id="signInButton"
                        onClick={submitHandler}
                        type="submit"
                        className="btn btn-outline-dark btn-lg"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </form>
                {loading && <FullLoader width='5rem'/>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default connect(null, { login: accountThunks.login })(Login as any);
