import React from 'react';
import './Modal.css';
type Props = {
  Footer?: React.FC;
  visibility: boolean;
  title?: string;
  xl?: boolean;
  children: JSX.Element;
  scroll?: boolean;
  closeFunc: () => any;
};
const Modal: React.FC<Props> = ({
  visibility = false,
  title = '',
  closeFunc = () => {},
  scroll = false,
  Footer,
  children,
  xl = false,
}:Props) => {
  return (
    <div className={`modal ${visibility ? 'show' : ''} ${scroll ? 'scroll': ''}`} style={{ display: `${visibility ? 'block' : 'none'}` }}>
      <div className={`Modal ${xl ? '--XL' : ''} long-modal modal-dialog modal-dialog-centered`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button className="close" onClick={closeFunc}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
          {Footer && (
            <div className="modal-footer">
              <Footer />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
