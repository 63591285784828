import { Link } from 'react-router-dom';
import './AdminLinks.css';

const AdminLinksItems = [
  {
    to: '/aws-monitor',
    title: 'AWS Monitor',
  },
  {
    to: '/all-sites-management',
    title: 'All sites management',
  },
  {
    to: '/wp-services',
    title: 'WP Settings',
  },
];

const AdminLinks = function () {
  /* eslint-disable */
  return (
    <ul className="admin-links mb-0">
      {AdminLinksItems.map((item:{to: string, title:string}, index) => (
        <Link to={item.to} key={index} className="admin-link">
          <li className="admin-item ">
            <span>{item.title}</span>
          </li>
        </Link>
      ))}
    </ul>
  );
};

export default AdminLinks;
