import React, { useRef, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../Helpers/TableIcons';
import { WpSettingsRequests } from '../../api/adminRequests/index';
import Modal from '../../components/Modal/Modal';
import Button from '@material-ui/core/Button';
import { Icon } from '../../components/Icon';
import { mdiClose, mdiPencil, mdiPlus } from '@mdi/js';
import './WPServices.css';
import { TableOptions } from '../../Helpers/PagesCommonData';
import { TableCellStyle, TableHeaderStyle } from '../../Helpers/PagesCommonData';

interface WPServicesProps {
  handleStatusCode?: (status: number) => {};
}
const WPServices = ({ handleStatusCode }: React.PropsWithChildren<WPServicesProps>) => {
  const [isAddTemplateModal, setIsAddTemplateModal] = useState<boolean>(false);
  const [isEditTemplateModal, setIsEditTemplateModal] = useState<boolean>(false);
  const [isDeleteTemplateModal, setIsDeleteTemplateModal] = useState<boolean>(false);
  const [isAddVideoModal, setIsAddVideoModal] = useState<boolean>(false);
  const [isEditVideoModal, setIsEditVideoModal] = useState<boolean>(false);
  const [isDeleteVideoModal, setIsDeleteVideoModal] = useState<boolean>(false);
  const [videoName, setVideoName] = useState<string>('');
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoId, setVideoId] = useState<string | number>('');

  const [templateName, setTemplateName] = useState<string>('');
  const [templateId, setTemplateId] = useState<string | number>('');
  const [templateDomain, setTemplateDomain] = useState<string>('');
  const [templateUrl, setTemplateUrl] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const [templateDbName, setTemplateDbName] = useState<string>('');
  const [templatePathToFiles, setTemplatePathToFiles] = useState<string>('');
  


  const addTemplateAndRefresh = () => {
    WpSettingsRequests.createTemplate({
      Title: templateName,
      Domain: templateDomain,
      Url: templateUrl,
      Description: templateDescription,
      DbName: templateDbName,
      PathToFiles: templatePathToFiles,
      statusCodeHandle: handleStatusCode,
      callback: () => templateTable && templateTable.current && templateTable.current.onQueryChange(),
    });
    closeModal();
  };

  const addVideoAndRefresh = () => {
    WpSettingsRequests.createVideo({
      Name: videoName,
      Url: videoUrl,
      statusCodeHandle: handleStatusCode,
      callback: () => videoTable && videoTable.current && videoTable.current.onQueryChange(),
    });
    closeModal();
  };

  const editTemplateModal = (id: string | number,  templateName: string, templateUrl:string, templateDomain: string, templateDescription: string, templateDbName: string, templatePathToFiles: string) => {
    setTemplateId(id || (id === 0 ? 0 : ''));
    setTemplateName(templateName);
    setTemplateDomain(templateDomain );  
    setTemplateDescription(templateDescription);  
    setTemplateDbName(templateDbName);  
    setTemplatePathToFiles(templatePathToFiles);  
    setTemplateUrl(templateUrl);
    setIsEditTemplateModal(true);
  };

  const editVideoModal = (id: string | number, url: string, name: string) => {
    setVideoId(id || '');
    setVideoUrl(url || '');
    setVideoName(name || '');
    setIsEditVideoModal(true);
  };

  const editTemplateAndRefresh = () => {
    WpSettingsRequests.editTemplate({
      Id: templateId,
      Title: templateName,
      Domain: templateDomain,
      Url: templateUrl,
      Description: templateDescription,
      DbName: templateDbName,
      PathToFiles: templatePathToFiles,
      statusCodeHandle: handleStatusCode,
      callback: () => templateTable && templateTable.current && templateTable.current.onQueryChange(),
    });
    closeModal();
  };

  const editVideoAndRefresh = () => {
    WpSettingsRequests.editVideo({
      Id: videoId,
      Url: videoUrl,
      Name: videoName,
      statusCodeHandle: handleStatusCode,
      callback: () => videoTable && videoTable.current && videoTable.current.onQueryChange(),
    });
    closeModal();
  };

  const deleteTemplateAndRefresh = () => {
    WpSettingsRequests.deleteTemplate({
      Id: templateId,
      statusCodeHandle: handleStatusCode,
      callback: () => templateTable && templateTable.current && templateTable.current.onQueryChange(),
    });
    closeModal();
  };

  const deleteVideoAndRefresh = () => {
    WpSettingsRequests.deleteVideo({
      Id: videoId,
      statusCodeHandle: handleStatusCode,
      callback: () => videoTable && videoTable.current && videoTable.current.onQueryChange(),
    });
    closeModal();
  };

  const closeModal = () => {
    setTemplateId('');
    setTemplateName('');
    setVideoName('');
    setVideoUrl('');
    setVideoId('');
    setIsAddVideoModal(false);
    setIsEditVideoModal(false);
    setIsDeleteVideoModal(false);
    setIsAddTemplateModal(false);
    setIsEditTemplateModal(false);
    setIsDeleteTemplateModal(false);
  };

  let templateTable = useRef<any>(null);
  let videoTable = useRef<any>(null);
  return (
    <div className="WPTables">
      <div className="Settings-Header-Row-One">
        <h3 className="Settings-Title">WORDPRESS SETTINGS</h3>
        <div className="add-button-container">
          <Button
            variant="contained"
            style={{ color: '#fff', backgroundColor: '#16D39A' }}
            startIcon={<Icon style={{ transform: 'translateY(2px)' }} path={mdiPlus} />}
            onClick={() => {
              setTemplateId(0);
              setIsAddTemplateModal(true);
            }}
          >
            Add Template
          </Button>
        </div>
      </div>
      <div className="position-relative Tables">
        <div className="Tables-table-container">
          <MaterialTable
            icons={tableIcons}
            options={TableOptions as any}
            localization={{
              toolbar: {
                searchPlaceholder: '',
              },
            }}
            tableRef={templateTable}
            title={<h4 style={{ fontSize: '1.12rem', fontWeight: 'lighter' }}>WORDPRESS TEMPLATES</h4>}
            columns={[
              { field: 'Id', hidden: true },
              {
                title: <span style={{ minWidth: 'max-content' }}>Title</span>,
                field: 'Title',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.Title}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Domain</span>,
                field: 'Domain',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.Domain}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Url</span>,
                field: 'Url',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.Url}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Data Base Name</span>,
                field: 'DbName',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.DbName}</div>,
              },
              
              {
                title: <span style={{ minWidth: 'max-content' }}>Folder name</span>,
                field: 'PathToFiles',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.PathToFiles}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Description</span>,
                field: 'Description',
                cellStyle: { ...TableCellStyle, whiteSpace: 'normal', overflow: 'auto', padding: "4px" },
                headerStyle: TableHeaderStyle,
                render: rowData => <div style={{ maxHeight: '50px', overflowY: 'auto'}}>{rowData.Description}</div>,
              },
              {
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => (
                  <div style={{ textAlign: 'center', minWidth: 'max-content' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="mx-2"
                      style={{
                        color: '#fff',
                        borderRadius: '.21rem',
                        backgroundColor: '#2DCEE3',
                        borderColor: '#22C2DC',
                        padding: '0.36rem 0.5rem',
                        minWidth: '0',
                        marginRight: '0.25rem',
                      }}
                      onClick={() => {
                        editTemplateModal(rowData.Id, rowData.Title,  rowData.Url, rowData.Domain, rowData.Description, rowData.DbName, rowData.PathToFiles);
                      }}
                    >
                      <Icon size={18} path={mdiPencil} />
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        color: '#fff',
                        borderRadius: '.21rem',
                        backgroundColor: '#FF7588',
                        borderColor: '#FF6275',
                        padding: '0.36rem 0.5rem',
                        minWidth: '0',
                        marginRight: '0.25rem',
                      }}
                      className="mx-2"
                      onClick={() => {
                        setTemplateId(rowData.Id || '');
                        setTemplateName(rowData.Title || '');
                        setIsDeleteTemplateModal(true);
                      }}
                    >
                      <Icon size={18} path={mdiClose} />
                    </Button>
                  </div>
                ),
              },
            ]}
            data={query =>
              new Promise(async (resolve, reject) => {
                const records = await WpSettingsRequests.getTemplatesByPage(
                  query?.search,
                  query?.orderBy?.field,
                  query?.orderDirection,
                  query?.page,
                  query?.pageSize,
                  handleStatusCode
                ).then(response => {
                  console.log(response);  
                  return response;
                });

                resolve({
                  data: records ? (records?.Templates as never[]) : [],
                  page: records ? records?.PageNumber : 0,
                  totalCount: records ? records?.NumberOfAllRecords : 0,
                });
              })
            }
          />
        </div>
      </div>
      <div className="Settings-Header-Row-One">
        <div style={{marginTop: "25px"}} className="add-button-container">
          <Button
            variant="contained"
            style={{ color: '#fff', backgroundColor: '#16D39A' }}
            startIcon={<Icon style={{ transform: 'translateY(2px)' }} path={mdiPlus} />}
            onClick={() => {
              setVideoId(0);
              setIsAddVideoModal(true);
            }}
          >
            Add Video URL
          </Button>
        </div>
      </div>
      <div className="position-relative Tables">
        <div className="Tables-table-container">
          <MaterialTable
            icons={tableIcons}
            options={TableOptions as any}
            localization={{
              toolbar: {
                searchPlaceholder: '',
              },
            }}
            tableRef={videoTable}
            title={<h4 style={{ fontSize: '1.12rem', fontWeight: 'lighter' }}>VIDEO URLS</h4>}
            columns={[
              { field: 'Id', hidden: true },
              {
                title: <span style={{ minWidth: 'max-content' }}>URL</span>,
                field: 'Url',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.Url}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Name</span>,
                field: 'Name',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.Name}</div>,
              },
              { field: 'Id', hidden: true },
              {
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => (
                  <div style={{ textAlign: 'center', minWidth: 'max-content' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="mx-2"
                      style={{
                        color: '#fff',
                        borderRadius: '.21rem',
                        backgroundColor: '#2DCEE3',
                        borderColor: '#22C2DC',
                        padding: '0.36rem 0.5rem',
                        minWidth: '0',
                        marginRight: '0.25rem',
                      }}
                      onClick={() => {
                        editVideoModal(rowData.Id, rowData.Url, rowData.Name);
                      }}
                    >
                      <Icon size={18} path={mdiPencil} />
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        color: '#fff',
                        borderRadius: '.21rem',
                        backgroundColor: '#FF7588',
                        borderColor: '#FF6275',
                        padding: '0.36rem 0.5rem',
                        minWidth: '0',
                        marginRight: '0.25rem',
                      }}
                      className="mx-2"
                      onClick={() => {
                        setVideoId(rowData.Id || '');
                        setVideoName(rowData.Name || '');
                        setIsDeleteVideoModal(true);
                      }}
                    >
                      <Icon size={18} path={mdiClose} />
                    </Button>
                  </div>
                ),
              },
            ]}
            data={query =>
              new Promise(async (resolve, reject) => {
                const records = await WpSettingsRequests.getVideoUrlsByPage(
                  query?.search,
                  query?.orderBy?.field,
                  query?.orderDirection,
                  query?.page,
                  query?.pageSize,
                  handleStatusCode
                ).then(response => {
                  return response;
                });

                resolve({
                  data: records ? (records?.VideoUrls as never[]) : [],
                  page: records ? records?.PageNumber : 0,
                  totalCount: records ? records?.NumberOfAllRecords : 0,
                });
              })
            }
          />
        </div>
      </div>
      {/* Templates Modals */}
      <Modal title={'Add Template'} visibility={isAddTemplateModal} closeFunc={closeModal}>
        <div style={{ position: 'relative' }}>
          <div>
            <strong>NOTE:</strong> 
            <br/>
            <small>For the template to work correctly, please install the following WordPress plugins:</small>
            <br/>
            <small>- Adminimize Version 1.11.11</small>
            <br/>
            <small>- JWT Authentication for WP-API Version 1.3.4</small>
            <br/>
            <small>- Shortcoder Version 6.3.2</small>
            <br/>
            <small>- creims-clone (Plugin developed by "site builder" team)</small>
            <br/>
          </div>
          <br/>
          <form className="text-white" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
              <label className="control-label" htmlFor="name">
                Title
              </label>
              <input
                onChange={e => {
                  setTemplateName(e.currentTarget.value);
                }}
                value={templateName}
                className={`form-control`}
                name="name"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Domain">
                Domain <strong>Note:</strong> <small>without http|s:// and "/" at the end</small>
              </label>
              <input
                onChange={e => {
                  setTemplateDomain(e.currentTarget.value);
                }}
                value={templateDomain}
                className={`form-control`}
                name="Domain"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Url">
                Url
              </label>
              <input
                onChange={e => {
                  setTemplateUrl(e.currentTarget.value);
                }}
                value={templateUrl}
                className={`form-control`}
                name="Url"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Database">
                Database name
              </label>
              <input
                onChange={e => {
                  setTemplateDbName(e.currentTarget.value);
                }}
                value={templateDbName}
                className={`form-control`}
                name="Database"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Folder">
                Folder name  <strong>Note:</strong> <small>name of folder where hosted template</small>
              </label>
              <input
                onChange={e => {
                  setTemplatePathToFiles(e.currentTarget.value);
                }}
                value={templatePathToFiles}
                className={`form-control`}
                name="Folder"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Description">
              Description
              </label>
              <input
                onChange={e => {
                  setTemplateDescription(e.currentTarget.value);
                }}
                value={templateDescription}
                className={`form-control`}
                name="Description"
              />
            </div>
            <div className="form-group">
              <div className="col-md-6 offset-md-3">
                <Button
                  variant="contained"
                  onClick={addTemplateAndRefresh}
                  className="btn btn-block"
                  style={{ backgroundColor: 'rgb(22, 211, 154)', color: '#fff' }}
                  disabled={
                    !templateName?.trim() ||
                    !templateId?.toString().trim() ||
                    !templateDomain?.trim() ||
                    !templateUrl?.trim() ||
                    !templateDbName?.trim() ||
                    !templatePathToFiles?.trim()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal title={'Edit Template'} visibility={isEditTemplateModal} closeFunc={closeModal}>
        <div style={{ position: 'relative' }}>
          <form className="text-white" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
              <label className="control-label" htmlFor="name">
                Title
              </label>
              <input
                onChange={e => {
                  setTemplateName(e.currentTarget.value);
                }}
                value={templateName}
                className={`form-control`}
                name="Title"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Domain">
                Domain <strong>Note:</strong> <small>without http|s:// and "/" at the end</small>
              </label>
              <input
                onChange={e => {
                  setTemplateDomain(e.currentTarget.value);
                }}
                value={templateDomain}
                className={`form-control`}
                name="Domain"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Url">
                Url
              </label>
              <input
                onChange={e => {
                  setTemplateUrl(e.currentTarget.value);
                }}
                value={templateUrl}
                className={`form-control`}
                name="Url"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Database">
                Database name
              </label>
              <input
                onChange={e => {
                  setTemplateDbName(e.currentTarget.value);
                }}
                value={templateDbName}
                className={`form-control`}
                name="Database"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Folder">
                Folder name  <strong>Note:</strong> <small>name of folder where hosted template</small>
              </label>
              <input
                onChange={e => {
                  setTemplatePathToFiles(e.currentTarget.value);
                }}
                value={templatePathToFiles}
                className={`form-control`}
                name="Folder"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="Description">
              Description
              </label>
              <input
                onChange={e => {
                  setTemplateDescription(e.currentTarget.value);
                }}
                value={templateDescription}
                className={`form-control`}
                name="Description"
              />
            </div>
            <div className="form-group">
              <div className="col-md-6 offset-md-3">
                <Button
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: 'rgb(45, 206, 227)',
                    borderColor: 'rgb(34, 194, 220)',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  variant="contained"
                  className="btn btn-primary btn-block"
                  disabled={
                    !templateName?.trim() ||
                    !templateId?.toString().trim() ||
                    !templateDomain?.trim() ||
                    !templateUrl?.trim() ||
                    !templateDbName?.trim() ||
                    !templatePathToFiles?.trim()
                  }
                  onClick={editTemplateAndRefresh}>
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal title={'Delete Template'} visibility={isDeleteTemplateModal} closeFunc={closeModal}>
        <div style={{ position: 'relative' }}>
          <h5 className="text-center">Are you sure you want to delete template: "{templateName}"?</h5>
          <div>
            <button
              style={{
                color: '#fff',
                borderRadius: '.21rem',
                backgroundColor: '#FF7588',
                borderColor: '#FF6275',
                padding: '0.36rem 0.5rem',
                minWidth: '0',
                marginRight: '0.25rem',
              }}
              onClick={deleteTemplateAndRefresh}
              className="btn btn-danger btn-block"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal title={'Add Video URL'} visibility={isAddVideoModal} closeFunc={closeModal}>
        <div style={{ position: 'relative' }}>
          <form className="text-white" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
              <label className="control-label" htmlFor="name">
                Video Name
              </label>
              <input
                onChange={e => {
                  setVideoName(e.currentTarget.value);
                }}
                value={videoName}
                className={`form-control`}
                name="name"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="id">
                URL
              </label>
              <input
                onChange={e => {
                  setVideoUrl(e.currentTarget.value);
                }}
                value={videoUrl}
                className={`form-control`}
                name="url"
              />
            </div>
            <div className="form-group">
              <div className="col-md-6 offset-md-3">
                <Button
                  variant="contained"
                  onClick={addVideoAndRefresh}
                  className="btn btn-block"
                  style={{ backgroundColor: 'rgb(22, 211, 154)', color: '#fff' }}
                  disabled={!videoName.trim() || !videoUrl.trim()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal title={'Edit Video URL'} visibility={isEditVideoModal} closeFunc={closeModal}>
        <div style={{ position: 'relative' }}>
          <form className="text-white" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
              <label className="control-label" htmlFor="name">
                Video Name
              </label>
              <input
                onChange={e => {
                  setVideoName(e.currentTarget.value);
                }}
                value={videoName}
                className={`form-control`}
                name="name"
              />
            </div>
            <div className="form-group">
              <label className="control-label" htmlFor="url">
                URL
              </label>
              <input
                onChange={e => {
                  setVideoUrl(e.currentTarget.value);
                }}
                value={videoUrl}
                className={`form-control`}
                name="url"
              />
            </div>
            <div className="form-group">
              <div className="col-md-6 offset-md-3">
                <Button
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: 'rgb(45, 206, 227)',
                    borderColor: 'rgb(34, 194, 220)',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  variant="contained"
                  className="btn btn-primary btn-block"
                  disabled={!videoName.trim() || !videoUrl.trim()}
                  onClick={editVideoAndRefresh}
                >
                  Edit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal title={'Delete Video URL'} visibility={isDeleteVideoModal} closeFunc={closeModal}>
        <div style={{ position: 'relative' }}>
          <h5 className="text-center">Are you sure you want to delete Video URL: "{videoName}"?</h5>
          <div>
            <button
              style={{
                color: '#fff',
                borderRadius: '.21rem',
                backgroundColor: '#FF7588',
                borderColor: '#FF6275',
                padding: '0.36rem 0.5rem',
                minWidth: '0',
                marginRight: '0.25rem',
              }}
              onClick={deleteVideoAndRefresh}
              className="btn btn-danger btn-block"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WPServices;
