import React, { useRef, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../Helpers/TableIcons';
import Modal from '../../components/Modal/Modal';
import Button from '@material-ui/core/Button';
import { Icon } from '../../components/Icon';
import { AwsMonitoringRequests } from '../../api/adminRequests/index';
import { mdiClipboardArrowDownOutline, mdiClose, mdiEye, mdiPlus } from '@mdi/js';
import './AwsMonitor.css';
import { TableCellStyle, TableHeaderStyle } from '../../Helpers/PagesCommonData';

interface AwsMonitorProps {
  handleStatusCode?: (status: number) => {};
}
const AwsMonitor = ({ handleStatusCode }: React.PropsWithChildren<AwsMonitorProps>) => {
  const [openTeminateInstanceModal, setOpenTerminateInstanceModal] = useState<boolean>(false);
  const [isLaunchInstanceModal, setIsLaunchInstanceModal] = useState<boolean>(false);

  const [details, setDetails] = useState<boolean>(false);
  const [instanceId, setInstanceId] = useState<string>('');
  const [instanceRecordId, setInstanceRecordId] = useState<string>('');
  const [instanceIp, setInstanceIp] = useState<string>('');
  const [instanceCapacity, setInstanceCapacity] = useState<string | number>(500);

  let siteTable = useRef<any>(null);
  let instanceTable = useRef<any>(null);

  const openDetails = (InstanceId: string, IpAddress: string, InstanceRecordId: string) => {
    setInstanceId(InstanceId);
    setInstanceIp(IpAddress);
    setInstanceRecordId(InstanceRecordId);
    siteTable && siteTable.current && siteTable.current.onQueryChange();
    setDetails(true);
  };

  function launchAndRefresh() {
    AwsMonitoringRequests.launchInstace(
      instanceCapacity,
      () => {
        launchInstanceModalClose();
        instanceTable && instanceTable.current && instanceTable.current.onQueryChange();
      },
      handleStatusCode
    );
    launchInstanceModalClose()
  }

  function terminateAndRefresh() {
    AwsMonitoringRequests.terminateInstance(
      () => instanceTable && instanceTable.current && instanceTable.current.onQueryChange(),
      instanceId,
      handleStatusCode
    );
    terminateInstanceModalClose()
  }

  const launchInstanceModalClose = () => {
    setInstanceCapacity(1);
    setIsLaunchInstanceModal(false);
  };

  function terminateInstanceModalClose() {
    setInstanceId('');
    setOpenTerminateInstanceModal(false);
  }

  return (
    <div className="AwsMonitor">
      <div className="AwsMonitor-Header">
        <h3 className="AwsMonitor-Title">AWS MONITOR</h3>
        <div>
          {details && (
            <Button
              variant="contained"
              style={{ color: '#fff', backgroundColor: '#FF7588', borderColor: '#FF6275', margin: '0 0.5rem' }}
              startIcon={<Icon path={mdiClose} />}
              onClick={() => setDetails(false)}
            >
              Close details
            </Button>
          )}
          <Button
            variant="contained"
            style={{ backgroundColor: 'rgb(22, 211, 154)', color: '#fff' }}
            startIcon={<Icon style={{ transform: 'translateY(2px)' }} path={mdiPlus} />}
            onClick={() => setIsLaunchInstanceModal(true)}
          >
            Launch Instance
          </Button>
        </div>
      </div>
      <div className="position-relative">
        <MaterialTable
          icons={tableIcons}
          options={{
            searchFieldVariant: 'outlined',
            maxBodyHeight: 590,
            initialPage: 0,
            paging: true,
            sorting: true,
            emptyRowsWhenPaging: false,
            paginationType: 'stepped',
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            showFirstLastPageButtons: true,
            thirdSortClick: false,
            headerStyle: {
              border: '1px solid #e3ebf3',
            },
          }}
          localization={{
            toolbar: {
              searchPlaceholder: '',
            },
          }}
          tableRef={instanceTable}
          title={<h4 style={{ fontSize: '1.12rem', fontWeight: 'lighter' }}>AWS MONITORING</h4>}
          columns={[
            { field: 'Id', hidden: true },
            {
              title: <span style={{ minWidth: 'max-content' }}>Ip Address</span>,
              field: 'IpAddress',
              cellStyle: TableCellStyle,
              headerStyle: TableHeaderStyle,
              render: rowData => <div className="table--full-width">{rowData.IpAddress}</div>,
            },
            {
              title: <span style={{ minWidth: 'max-content' }}>Instance Id</span>,
              field: 'InstanceId',
              cellStyle: TableCellStyle,
              headerStyle: TableHeaderStyle,
              render: rowData => <div className="table--full-width">{rowData.InstanceId}</div>,
            },
            {
              title: <span style={{ minWidth: 'max-content' }}>Status</span>,
              field: 'Status',
              cellStyle: TableCellStyle,
              headerStyle: TableHeaderStyle,
              render: rowData => <div className="table--full-width">{rowData.Status}</div>,
            },
            {
              title: <span style={{ minWidth: 'max-content' }}>Count Sites</span>,
              field: 'CountSites',
              cellStyle: TableCellStyle,
              headerStyle: TableHeaderStyle,
              render: rowData => <div className="table--full-width">{rowData.CountSites}</div>,
            },
            {
              title: <span style={{ minWidth: 'max-content' }}>Capacity</span>,
              field: 'Capacity',
              cellStyle: TableCellStyle,
              headerStyle: TableHeaderStyle,
              render: rowData => <div className="table--full-width">{rowData.Capacity}</div>,
            },
            {
              cellStyle: TableCellStyle,
              headerStyle: TableHeaderStyle,
              render: rowData => (
                <div style={{ minWidth: 'max-content' }}>
                  <Button
                    variant="contained"
                    style={{
                      color: '#fff',
                      borderRadius: '.21rem',
                      backgroundColor: '#16D39A',
                      borderColor: '#10C888',
                      minWidth: '0',
                      marginRight: '0.25rem',
                      padding: 0,
                    }}
                    onClick={() => openDetails(rowData.InstanceId, rowData.IpAddress, rowData.Id)}
                  >
                    <span className="AwsMonitor-Icon-Container">
                      <Icon size={16} path={mdiClipboardArrowDownOutline} />
                    </span>
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: '#fff',
                      borderRadius: '.21rem',
                      backgroundColor: '#FF7588',
                      borderColor: '#FF6275',
                      padding: '0.36rem 0.5rem',
                      minWidth: '0',
                      marginRight: '0.25rem',
                    }}
                    onClick={() => {
                      setInstanceId(rowData.Id);
                      setOpenTerminateInstanceModal(true);
                    }}
                  >
                    <Icon size={18} path={mdiClose} />
                  </Button>
                </div>
              ),
            },
          ]}
          data={query =>
            new Promise(async (resolve, reject) => {
              const records = await AwsMonitoringRequests.getInstancesByPage(
                query?.search,
                query?.orderBy?.field,
                query?.orderDirection,
                query?.page,
                query?.pageSize,
                handleStatusCode
              ).then(response => {
                return response;
              });

              resolve({
                data: records ? (records.Instances as never[]) : [],
                page: records?.PageNumber,
                totalCount: records?.NumberOfInstances,
              });
            })
          }
        />

        {details && (
          <MaterialTable
            icons={tableIcons}
            style={{ position: 'relative', left: '0', right: '0', marginTop: '0.5rem' }}
            options={{
              searchFieldVariant: 'outlined',
              maxBodyHeight: 590,
              initialPage: 0,
              paging: true,
              sorting: true,
              emptyRowsWhenPaging: false,
              paginationType: 'stepped',
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 100],
              showFirstLastPageButtons: true,
              thirdSortClick: false,
              headerStyle: {
                border: '1px solid #e3ebf3',
              },
            }}
            localization={{
              toolbar: {
                searchPlaceholder: '',
              },
            }}
            tableRef={siteTable}
            title={
              <h4 style={{ fontSize: '1.12rem', fontWeight: 'lighter' }}>
                Sites on instance {instanceId} ({instanceIp})
              </h4>
            }
            columns={[
              {
                field: 'Id',
                hidden: true,
              },
              {
                field: 'Email',
                title: <span style={{ minWidth: 'max-content' }}>User Name</span>,
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.Email}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Site</span>,
                field: 'FullDomainName',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => (
                  <div className="table--full-width">
                    {rowData?.CustomDomainName?.trim() ? rowData.CustomDomainName : rowData.FullDomainName}
                  </div>
                ),
              },
              {
                field: 'CustomDomainName',
                hidden: true,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Company Name</span>,
                field: 'CompanyName',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.CompanyName}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Target City</span>,
                field: 'City',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.City}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Target State</span>,
                field: 'State',
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => <div className="table--full-width">{rowData.State}</div>,
              },
              {
                title: <span style={{ minWidth: 'max-content' }}>Phone</span>,
                field: 'Phone',
                render: rowData => <div className="table--full-width">{rowData.Phone}</div>,
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
              },
              { field: 'Url', hidden: true },
              {
                cellStyle: TableCellStyle,
                headerStyle: TableHeaderStyle,
                render: rowData => (
                  <div style={{ minWidth: 'max-content' }}>
                    <Button
                      variant="contained"
                      style={{
                        color: '#fff',
                        borderRadius: '.21rem',
                        backgroundColor: '#16D39A',
                        borderColor: '#10C888',
                        minWidth: '0',
                        marginRight: '0.25rem',
                        padding: 0,
                        width: '31px',
                        height: '28px',
                      }}
                      className="AwsMonitor-Icon-Container"
                    >
                      <a
                        style={{
                          color: 'inherit',
                          textDecoration: 'none',
                          width: '100%',
                          height: '100%',
                        }}
                        href={!rowData?.CustomDomainName?.trim() ? rowData.Url : 'https://' + rowData.CustomDomainName}
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        <Icon size={18} path={mdiEye} />
                      </a>
                    </Button>
                  </div>
                ),
              },
            ]}
            data={query =>
              new Promise(async (resolve, reject) => {
                const records = await AwsMonitoringRequests.getSitesOnInstanceByPage(
                  instanceRecordId,
                  query?.search,
                  query?.orderBy?.field,
                  query?.orderDirection,
                  query?.page,
                  query?.pageSize,
                  handleStatusCode
                ).then(response => {
                  return response;
                });

                resolve({
                  data: records ? (records.SitesOnInstance as never[]) : [],
                  page: records.PageNumber,
                  totalCount: records.NumberOfAllSites,
                });
              })
            }
          />
        )}
      </div>
      <Modal
        title={'Launch Instance'}
        visibility={isLaunchInstanceModal}
        closeFunc={() => {
          setIsLaunchInstanceModal(false);
          setInstanceCapacity(500);
        }}
      >
        <div style={{ position: 'relative' }}>
          <form className="text-white" onSubmit={e => e.preventDefault()}>
            <div className="form-group">
              <label className="control-label" htmlFor="capacity">
                Capacity
              </label>
              <input
                onChange={e => {
                  +e.currentTarget.value < 1 ? setInstanceCapacity(1) : setInstanceCapacity(e.currentTarget.value);
                  +e.currentTarget.value > 500 ? setInstanceCapacity(500) : setInstanceCapacity(e.currentTarget.value);
                }}
                min={1}
                max={500}
                value={instanceCapacity}
                type="number"
                className={`form-control ${+instanceCapacity < 1 ? '--invalid' : ''}`}
                name="capacity"
              />
            </div>
            <div className="form-group">
              <div className="col-md-6 offset-md-3">
                <Button
                  disabled={(+instanceCapacity) < 1}
                  onClick={launchAndRefresh}
                  variant="contained"
                  style={{ backgroundColor: 'rgb(22, 211, 154)' }}
                  className="btn btn-block"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      <Modal
        title={'Terminate instance'}
        visibility={openTeminateInstanceModal}
        closeFunc={terminateInstanceModalClose}
      >
        <div style={{ position: 'relative' }}>
          <h5 className="text-center">Are you sure you want to terminate this instance?</h5>
          <div>
            <button
              style={{
                color: '#fff',
                borderColor: 'rgb(255, 98, 117)',
                backgroundColor: 'rgb(255, 117, 136)',
                margin: '0 0.5rem',
                maxWidth: '100%'
              }}
              onClick={terminateAndRefresh}
              className="btn btn-block"
            >
              Terminate
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AwsMonitor;
