import React, { useEffect, useRef, useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../../Helpers/TableIcons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Modal from '../../components/Modal/Modal';
import Button from '@material-ui/core/Button';
import { Icon } from '../../components/Icon';
import { mdiClipboardArrowDownOutline, mdiClose, mdiEye, mdiPencil, mdiPlus, mdiTag } from '@mdi/js';
import InputMask from 'react-input-mask';
import './MySites.css';
import usersRequest from '../../api/usersRequests';
import states from './States';
import FullLoader from '../../components/Loader/FullLoader/FullLoader';
import { store } from '../../app/store';
import { TableCellStyle, TableHeaderStyle, TableOptions } from '../../Helpers/PagesCommonData';
import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
interface MySitesProps {
  handleStatusCode?: (status: number) => {};
}

const maxDomainSize = 63;

const MySites = ({ handleStatusCode }: React.PropsWithChildren<MySitesProps>) => {
  const [isSiteCreateModal, setIsSiteCreateModal] = useState<boolean>(false);
  const [isSiteDeleteModal, setIsSiteDeleteModal] = useState<boolean>(false);
  const [isSiteEditModal, setIsSiteEditModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [domainName, setDomainName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [videoId, setVideoId] = useState<number>(-1);
  const [state, setState] = useState<string>(states[0]);
  const [siteTemplates, setSiteTemplates] = useState<any[]>([]);
  const [videoTemplates, setVideoTemplates] = useState<any[]>([]);
  const [currentSiteTemplateId, setCurrentSiteTemplateId] = useState<number>(-1);
  const [currentVideoTemplateId, setCurrentVideoTemplateId] = useState<number>(-1);
  const [currentViewSiteTemplateBTN, setCurrentViewSiteTemplateBTN] = useState<JSX.Element>();
  const [currentViewVideoTemplateBTN, setCurrentViewVideoTemplateBTN] = useState<JSX.Element>();
  const [isLoadSiteTemplates, setIsLoadSiteTemplates] = useState<boolean>(false);
  const [isLoadVideoTemplates, setIsLoadVideoTemplates] = useState<boolean>(false);
  const [city, setCity] = useState<string>('');
  const [currentSitesNumber, setCurrentSite] = useState<number>(0);
  const [maximumSitesNumber, setMaximumSitesNumber] = useState<number>(0);
  const [wpSiteId, setWpSiteId] = useState<string | number>('');
  const siteTable = useRef<any>(null);
  const [customDomainName, setCustomDomainName] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const [firstPhoneNumber, setFirstPhoneNumber] = useState<string>('');
  const [firstCustomDomainName, setFirstCustomDomainName] = useState<string>('');
  const [firstCompanyName, setFirstCompanyName] = useState<string>('');
  const [firstEmail, setFirstEmail] = useState<string>('');
  const [firstCity, setFirstCity] = useState<string>('');
  const [firstState, setFirstState] = useState<string>(states[0]);
  const [firstVideo, setFirstVideo] = useState<number>(-1);

  const [customDomainWarnings, setCustomDomainWarnings] = useState<string>('');
  const [defaultDomainWarnings, setDefaultDomainWarnings] = useState<string>('');

  const [adminPanel, setAdminPanel] = useState<string>('');

  const [isLoadingDefaultDomain, setIsLoadingDefaultDomain] = useState<boolean>(false);
  const [isLoadingCustomDomain, setIsLoadingCustomDomain] = useState<boolean>(false);

  useEffect(() => {
    siteTable && siteTable.current && siteTable.current.onQueryChange();
  }, [store.getState().account.account.email]);

  const hasNoInfusionSoftId = store.getState().account.account.hasNoInfusionSoftID;

  const viewSiteTemplateBTN = (templateId: number) => {
    const url = siteTemplates.find(element => element?.id === templateId)?.url;
    return (
      <Button
        disabled={templateId === -1}
        variant="contained"
        style={{
          color: '#fff',
          borderRadius: '.21rem',
          backgroundColor: '#16D39A',
          borderColor: '#10C888',
          minWidth: '0',
          marginRight: '0.25rem',
          padding: 0,
        }}
      >
        <a
          style={{ color: 'inherit', textDecoration: 'none', padding: '0.25rem 0.5rem' }}
          href={url}
          rel="noreferrer noopener"
          target="_blank"
        >
          <Icon size={18} path={mdiEye} />
        </a>
      </Button>
    );
  };
  const viewVideoTemplateBTN = (Id: number) => {
    const url = videoTemplates.find(element => element?.Id === Id)?.Url;
    return (
      <Button
        disabled={Id === -1}
        variant="contained"
        style={{
          color: '#fff',
          borderRadius: '.21rem',
          backgroundColor: '#16D39A',
          borderColor: '#10C888',
          minWidth: '0',
          marginRight: '0.25rem',
          padding: 0,
        }}
      >
        <a
          style={{ color: 'inherit', textDecoration: 'none', padding: '0.25rem 0.5rem' }}
          href={url}
          rel="noreferrer noopener"
          target="_blank"
        >
          <Icon size={18} path={mdiEye} />
        </a>
      </Button>
    );
  };


  const loadSiteTemplates = async () => {
    setIsLoadSiteTemplates(true);
    const templates = await usersRequest.getSiteTemplate(handleStatusCode);
    setSiteTemplates(templates);
    setIsLoadSiteTemplates(false);
  };
  const loadVideoTemplates = async () => {
    setIsLoadVideoTemplates(true);
    const videos = await usersRequest.getVideoTemplate(handleStatusCode);
    setVideoTemplates(videos);
    if(videoId){
      setCurrentViewVideoTemplateBTN(viewVideoTemplateBTN(videoId));
    }
    setIsLoadVideoTemplates(false);
  };
  const disabledScrollOnBody = (isDisable: boolean) => {
    if(isDisable){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'unset';
    }
  }

  const openSiteCreateModal = async () => {
    setIsSiteCreateModal(true);
    disabledScrollOnBody(true);
    loadSiteTemplates();
    loadVideoTemplates();
    setCurrentViewSiteTemplateBTN(viewSiteTemplateBTN(currentSiteTemplateId));
    setCurrentViewVideoTemplateBTN(viewVideoTemplateBTN(currentVideoTemplateId));
  };

  const createAndClose = () => {
    usersRequest.createSite(
      currentSiteTemplateId,
      currentVideoTemplateId,
      companyName,
      domainName,
      !customDomainName.toLowerCase()?.trim()
        ? ''
        : 'www.' + customDomainName.toLowerCase().replaceAll(/[^A-Za-z0-9-.]/gm, ''),
      email,
      phoneNumber.replaceAll(/\(|\)|-/gm, ''),
      state,
      city,
      () => siteTable && siteTable.current && siteTable.current.onQueryChange(),
      handleStatusCode
    );
    SiteCreateModalClose();
  };

  const SiteCreateModalClose = () => {
    setCompanyName('');
    disabledScrollOnBody(false);
    setDomainName('');
    setState(states[0]);
    setSiteTemplates([]);
    setVideoTemplates([]);
    setCurrentSiteTemplateId(-1);
    setCurrentVideoTemplateId(-1);
    setPhoneNumber('');
    setCity('');
    setCustomDomainName('');
    setWpSiteId('');
    setEmail('');
    setIsSiteCreateModal(false);
    setIsSiteEditModal(false);
    setIsSiteDeleteModal(false);
    setDefaultDomainWarnings('');
    setCustomDomainWarnings('');
  };

  const deleteAndRefresh = () => {
    usersRequest.deleteSite(
      wpSiteId,
      () => siteTable && siteTable.current && siteTable.current.onQueryChange(),
      handleStatusCode
    );
    setWpSiteId(0);
    setIsSiteDeleteModal(false);
  };

  const openSiteEditModal = (
    selectedVideoId: number,
    wpSiteId: string,
    companyName: string,
    domainName: string,
    phoneNumber: string,
    state: string,
    city: string,
    email: string,
    customDomainName: string
  ) => {
    const realCustomDomain = customDomainName
      .split('www.')
      .slice(1)
      .join('')
      .replaceAll(/[^A-Za-z0-9-.]/gm, '');
    let splitDomain = domainName.split('.dealmanagerpro.com');
    const defaultDomain = splitDomain.slice(0, splitDomain.length - 1).join('');
    disabledScrollOnBody(true);
    setFirstCompanyName(companyName);
    setFirstPhoneNumber(phoneNumber);
    setFirstState(state);
    setFirstCity(city);
    setFirstVideo(selectedVideoId);
    setFirstEmail(email);
    setFirstCustomDomainName(realCustomDomain);

    setCompanyName(companyName);
    setDomainName(defaultDomain);
    setPhoneNumber(phoneNumber);
    setState(state);
    setCity(city);
    setVideoId(selectedVideoId);
    setWpSiteId(wpSiteId);
    setEmail(email);
    setIsSiteEditModal(true);
    setCustomDomainName(realCustomDomain);
    loadVideoTemplates();
  };

  const isCanCreateSite = () =>{
    return (
      !companyName?.trim() ||
      !validateEmail(email) ||
      !phoneNumber?.trim() ||
      phoneNumber.includes('_') ||
      !city?.trim() ||
      (hasNoInfusionSoftId === 'False' && currentSiteTemplateId === -1 && currentVideoTemplateId !== -1) ||
      (customDomainName.length > 0 &&
        /[^A-Za-z0-9]/.test(customDomainName[customDomainName.length - 1])) ||
      /[^A-Za-z0-9]/.test(customDomainName[0]) ||
      (domainName.length > 0 && /[^A-Za-z0-9]/.test(domainName[domainName.length - 1])) ||
      /[^A-Za-z0-9]/.test(domainName[0]) ||
      (companyName.length !== 0 && domainName.length === 0) ||
      (customDomainName?.trim().length > 0 && domainName?.trim().length === 0) ||
      isLoadingCustomDomain ||
      isLoadingDefaultDomain ||
      !!customDomainWarnings?.trim() ||
      !!defaultDomainWarnings?.trim()
    );
  }

  const editSiteAndRefresh = () => {
    if (
      customDomainName !== firstCustomDomainName ||
      firstCity !== city ||
      firstPhoneNumber !== phoneNumber ||
      firstState !== state ||
      firstEmail !== email ||
      firstCompanyName !== companyName ||
      firstVideo !== videoId
    ) {
      usersRequest.updateSite({
        videoId,
        wpSiteId,
        companyName,
        customDomain: !customDomainName.toLowerCase()?.trim()
          ? ''
          : 'www.' + customDomainName.toLowerCase().replaceAll(/[^A-Za-z0-9-.]/gm, ''),
        phoneNumber: phoneNumber.replaceAll(/\(|\)|-/gm, ''),
        state,
        city,
        email,
        callback: () => {
          if (siteTable && siteTable.current) siteTable.current.onQueryChange();

          return 1;
        },
        statusCodeHandle: handleStatusCode,
      });
    }
    SiteCreateModalClose();
  };

  const companyNameBlur = async () => {
    if (domainName?.trim()) {
      if (!customDomainName?.trim()) {
        setIsLoadingDefaultDomain(true);
        const defResult = await usersRequest.IsHasDefaultDomain({ DefaultDomainName: domainName });
        if (!defResult?.isSuccessful) {
          setDefaultDomainWarnings(defResult?.message ? defResult?.message : '');
        } else {
          setDefaultDomainWarnings('');
        }
        setIsLoadingDefaultDomain(false);
      } else {
        setIsLoadingDefaultDomain(true);
        setIsLoadingCustomDomain(true);
        const defResult = await usersRequest.IsHasDefaultDomain({ DefaultDomainName: domainName });
        if (!defResult?.isSuccessful) {
          setDefaultDomainWarnings(defResult?.message ?? '');
        } else setDefaultDomainWarnings('');
        setIsLoadingDefaultDomain(false);
        const customResult = await usersRequest.IsCustomNameCorrespond({
          DefaultDomainName: domainName,
          CustomDomainName: customDomainName,
          statusCodeHandle: handleStatusCode,
        });
        if (!customResult?.isSuccessful) {
          setCustomDomainWarnings(customResult?.message ?? '');
        } else setCustomDomainWarnings('');
        setIsLoadingCustomDomain(false);
      }
    } else {
      setDefaultDomainWarnings('');
    }
  };

  const customDomainBlur = async () => {
    if (customDomainName?.trim()) {
      if (domainName?.trim()) {
        setIsLoadingCustomDomain(true);
        const customResult = await usersRequest.IsCustomNameCorrespond({
          DefaultDomainName: domainName,
          CustomDomainName: customDomainName,
          statusCodeHandle: handleStatusCode,
        });
        if (!customResult?.isSuccessful) {
          setCustomDomainWarnings(customResult?.message ?? '');
        } else setCustomDomainWarnings('');
        setIsLoadingCustomDomain(false);
      }
    } else {
      setCustomDomainWarnings('');
    }
  };

  return (
    <div className="MySites">
      <div className="Sites-Header">
        <h3 className="Sites-Title">SITES MANAGEMENT</h3>
        <div className="Sites-Count">
          <div className="Sites-UsersSites">
            Users sites: <span className="Selected">{currentSitesNumber}</span>
          </div>
          <div className="Sites-UsersMaxSites">
            Maximum number of sites: <span className="Selected">{maximumSitesNumber}</span>
          </div>
        </div>
        <div className="d-flex">
          {adminPanel && (
            <Button
              variant="contained"
              style={{ color: '#fff', backgroundColor: '#FF7588', borderColor: '#FF6275', margin: '0 0.5rem' }}
              startIcon={<Icon path={mdiClose} />}
              onClick={() => setAdminPanel('')}
            >
              Close details
            </Button>
          )}
          <Button
            disabled={currentSitesNumber >= maximumSitesNumber}
            variant="contained"
            className="Sites-Button"
            style={{ color: '#fff', backgroundColor: '#16D39A' }}
            onClick={() => openSiteCreateModal()}
            startIcon={<Icon style={{ transform: 'translateY(2px)' }} path={mdiPlus} />}
          >
            Create Site
          </Button>
        </div>
      </div>
      <MaterialTable
        icons={tableIcons}
        style={{ position: 'relative', left: '0', right: '0' }}
        options={TableOptions as any}
        localization={{
          toolbar: {
            searchPlaceholder: '',
          },
        }}
        tableRef={siteTable}
        title={<h4 style={{ fontSize: '1.12rem', fontWeight: 'lighter' }}>CURRENT ACTIVE SITES</h4>}
        columns={[
          {
            field: 'Id',
            hidden: true,
          },
          {
            field: 'VideoUrlId',
            hidden: true,
          },
          {
            field: 'WPEditorAutoSignInURL',
            hidden: true,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Site</span>,
            field: 'FullDomainName',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => (
              <div className="table--full-width">
                {rowData.CustomDomainName && rowData.CustomDomainName?.trim()
                  ? rowData.CustomDomainName
                  : rowData.FullDomainName}
              </div>
            ),
          },
          {
            field: 'CustomDomainName',
            hidden: true,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Company Name</span>,
            field: 'CompanyName',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.CompanyName}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Target City</span>,
            field: 'City',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.City}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Target State</span>,
            field: 'State',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.State}</div>,
          },
          {
            field: 'Email',
            title: <span style={{ minWidth: 'max-content' }}>Email</span>,
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.Email}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Phone</span>,
            field: 'Phone',
            render: rowData => <div className="table--full-width">{rowData.Phone}</div>,
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
          },
          { field: 'URl', hidden: true },
          {
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => (
              <div style={{ minWidth: 'max-content' }}>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#2DCEE3',
                    borderColor: '#22C2DC',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  onClick={() =>
                    openSiteEditModal(
                      rowData.VideoUrlId,
                      rowData.Id,
                      rowData.CompanyName,
                      rowData.FullDomainName,
                      rowData.Phone,
                      rowData.State,
                      rowData.City,
                      rowData.Email,
                      rowData.CustomDomainName
                    )
                  }
                >
                  <Icon size={18} path={mdiPencil} />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#16D39A',
                    borderColor: '#10C888',
                    minWidth: '0',
                    marginRight: '0.25rem',
                    padding: 0,
                  }}
                  onClick={() => setAdminPanel(rowData.WPEditorAutoSignInURL)}
                >
                  <span className="AwsMonitor-Icon-Container">
                    <Icon size={18} path={mdiClipboardArrowDownOutline} />
                  </span>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#16D39A',
                    borderColor: '#10C888',
                    minWidth: '0',
                    marginRight: '0.25rem',
                    padding: 0,
                  }}
                >
                  <a
                    style={{
                      color: 'inherit',
                      textDecoration: 'none',
                      padding: '0.25rem 0.5rem',
                    }}
                    href={
                      rowData.CustomDomainName
                        ? !rowData.CustomDomainName?.trim()
                          ? rowData.URl
                          : 'https://' + rowData.CustomDomainName
                        : rowData.URl
                    }
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <Icon size={18} path={mdiEye} />
                  </a>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#FF7588',
                    borderColor: '#FF6275',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  onClick={() => {
                    setIsSiteDeleteModal(true);
                    setWpSiteId(rowData.Id);
                  }}
                >
                  <Icon size={18} path={mdiClose} />
                </Button>
              </div>
            ),
          },
        ]}
        data={query =>
          new Promise(async resolve => {
            const records = await usersRequest
              .getSitesByPage(
                query?.search,
                query?.orderBy?.field,
                query?.orderDirection,
                query?.page,
                query?.pageSize,
                handleStatusCode
              )
              .then(response => {
                return response;
              });
            setCurrentSite(records?.NumberCreatedSitesByUser || 0);
            setMaximumSitesNumber(records?.TotalSitesForUser || 0);
            resolve({
              data: records ? (records.WordpressSites as never[]) : [],
              page: records ? (records?.PageNumber as number) : 0,
              totalCount: records ? (records?.NumberOfAllWordpressSites as number) : 0,
            });
          })
        }
      />

      {adminPanel && (
        <div className="AdminPanel">
          <div className="Sites-Header">
            <h3 className="Sites-Title">SITE ADMIN PANEL</h3>
          </div>
          <Alert style={{marginBottom: "20px"}} severity="info">
              <AlertTitle>Info</AlertTitle>
              For more information on editing your site, please visit the following page <a target="_blank" rel="noopener noreferrer" href='https://wordpress.com/support/wordpress-editor/'>WordPress guide</a>.
              If you are using the Brizy editor, you can refer to the following page <a target="_blank" rel="noopener noreferrer" href='https://support.brizy.io/hc/en-us/categories/360001183911-Brizy-WordPress-Plugin'>Brizy guide</a>.
          </Alert>
          <div className="AdminPanel--frameContainer">
            <iframe className="AdminPanel--frame" title='SITE ADMIN PANEL' src={adminPanel}>
              IFrame isn't supported
            </iframe>
          </div>
        </div>
      )}

      {/* Create Site Modal */}

      <Modal title={'Create Site'} scroll={true} visibility={isSiteCreateModal} xl={true} closeFunc={SiteCreateModalClose}>
        <div style={{ position: 'relative' }}>
          <form onSubmit={e => e.preventDefault()} className="needs-validation">
            <div>
              <h2 className="admin-box-title mb-2">
                <Icon path={mdiTag} size={18} className={'mr-1'} color="#00A5A8" /> <b>Site Info</b>
              </h2>
              <hr />
              <div className="mb-3">
                <label htmlFor="CompanyName">
                  Company Name:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  onBlur={companyNameBlur}
                  onChange={e => {
                    const currentValue = e.currentTarget.value;
                    const domainName = currentValue
                      .toString()
                      .toLowerCase()
                      .replaceAll(/[^A-Za-z0-9-.]/gm, '');
                    setCompanyName(e.currentTarget.value);

                    if (domainName.length <= maxDomainSize) setDomainName(domainName);
                  }}
                  type="text"
                  className={`form-control ${(companyName.length !== 0 && domainName.length === 0) ||
                    (domainName.length > 0 && /[^A-Za-z0-9]/.test(domainName[domainName.length - 1])) ||
                    /[^A-Za-z0-9]/.test(domainName[0]) ||
                    (customDomainName?.trim().length > 0 && domainName?.trim().length === 0)
                      ? '--invalid'
                      : ''
                  }`}
                  name="CompanyName"
                  value={companyName}
                />
              </div>

              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="DomainName">Domain Name:</label>
                  <div style={{ fontSize: '0.8rem', alignSelf: 'flex-end' }}>
                    <span>Symbols Left </span>
                    <span>{maxDomainSize - domainName.length}</span>
                    <span> / 63</span>
                  </div>
                </div>
                <div className="position-relative">
                  <div className="input-group">
                    <input
                      type="text"
                      readOnly
                      className={`form-control  ${(companyName.length !== 0 && domainName.length === 0) ||
                        (domainName.length > 0 && /[^A-Za-z0-9]/.test(domainName[domainName.length - 1])) ||
                        /[^A-Za-z0-9]/.test(domainName[0])
                          ? '--invalid'
                          : ''
                      }`}
                      name="DomainName"
                      value={domainName}
                    />
                    <span className="input-group-text">.dealmanagerpro.com</span>
                  </div>
                  {isLoadingDefaultDomain && <FullLoader width="0.5rem" />}
                </div>

                {defaultDomainWarnings && (
                  <div
                    style={{
                      textAlign: 'center',
                      background: '#f05e5e',
                      color: '#fff',
                      borderRadius: '0.25rem',
                      verticalAlign: 'middle',
                      fontSize: '0.8rem',
                      padding: '0.25rem',
                      marginTop: '0.25rem',
                    }}
                  >
                    {defaultDomainWarnings}
                  </div>
                )}
              </div>
            
              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="CustomDomainName">
                    Custom Domain Name
                    <IconButton style={{paddingRight: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: '2px'}} color='primary' onClick={(e)=>{setAnchorEl(e.currentTarget)}}>
                      <HelpOutlineIcon fontSize="small"/>
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => {setAnchorEl(null);}}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                        <Box style={{padding: '10px'}}>
                          <Typography variant="h6" gutterBottom style={{ display: 'block' }}>
                            For the use of a custom domain, you need:
                          </Typography>
                          <Typography variant="subtitle1"gutterBottom>
                            1. Buy or already have a domain;
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            2. In the DNS settings of your domain you need to create a CNAME record:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <div>- <span className='popover-bold'>Type</span> - CNAME;</div>
                            <div>- <span className='popover-bold'>Name</span> - is your custom domain. It must start with the www;</div>
                            <div>- <span className='popover-bold'>Target</span> - use the address from the "Domain Name" field above: <span className='popover-bold'>{domainName ? domainName:'some-name'}.dealmanagerpro.com</span></div>
                            <div>- <span className='popover-bold'>TTL</span> - 60 sec. or Auto.</div>
                            <div>- <span className='popover-bold'>Proxy</span> - if there is a proxy parameter, turn it off.</div>
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            3. Wait a few minutes while DNS registration record in network 
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <div className='popover-bold'>Example:</div>
                            <div>For www.custom.mydomain.com in DNS settings →</div>
                            <table className='popover-table'>
                              <tbody>
                                <tr>
                                  <td>Type</td>
                                  <td>Name</td>
                                  <td>Target</td>
                                  <td>TTL</td>
                                </tr>
                                <tr>
                                  <td>CNAME</td>
                                  <td className='popover-bold'>www.custom.mydomain.com</td>
                                  <td className='popover-styled-cell' >{domainName ? domainName:'some-name'}.dealmanagerpro.com</td>
                                  <td>60 sec.</td>
                                </tr>
                              </tbody>
                            </table>
                          </Typography>
                        </Box>
                    </Popover>
                  </label>
                  
                  
                  <span style={{ fontSize: '0.8rem', alignSelf: 'flex-end' }}>
                    Only letters in lowercase, numbers and hyphens are allowed
                  </span>
                </div>
                <div className="position-relative">
                  <div className="input-group">
                    <span className="input-group-text">www.</span>
                    <input
                      onBlur={customDomainBlur}
                      onChange={e => {
                        const domainWithoutSpaces = e.currentTarget.value
                          .toLowerCase()
                          .replaceAll(/[^A-Za-z0-9-.]/gm, '');
                        setCustomDomainName(domainWithoutSpaces);
                      }}
                      type="text"
                      className={`form-control ${(customDomainName.length > 0 &&
                          /[^A-Za-z0-9]/.test(customDomainName[customDomainName.length - 1])) ||
                        /[^A-Za-z0-9]/.test(customDomainName[0])
                          ? '--invalid'
                          : ''
                      }`}
                      name="CustomDomainName"
                      value={customDomainName}
                    />
                  </div>
                  {isLoadingCustomDomain && <FullLoader width="0.5rem" />}
                </div>

                {customDomainWarnings && (
                  <div
                    style={{
                      textAlign: 'center',
                      background: '#f05e5e',
                      color: '#fff',
                      borderRadius: '0.25rem',
                      verticalAlign: 'middle',
                      fontSize: '0.8rem',
                      padding: '0.25rem',
                      marginTop: '0.25rem',
                    }}
                  >
                    {customDomainWarnings}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email">
                  Email:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  type="text"
                  onChange={e => setEmail(e.currentTarget.value)}
                  className={`form-control ${validateEmail(email) || !email?.trim() ? '' : '--invalid'}`}
                  name="email"
                  value={email}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone">
                  Phone Number:
                  <span style={{ color: '#f00' }}> * </span>
                </label>

                <InputMask
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setPhoneNumber(e.currentTarget.value);
                  }}
                  type="tel"
                  name="phone"
                  mask="(999)-999-9999"
                  className={`form-control ${!phoneNumber.includes('_') ? '' : '--invalid'}`}
                  maskPlaceholder={'_'}
                  alwaysShowMask={true}
                  value={phoneNumber}
                />
              </div>
              <h2 className="admin-box-title mb-2">
                <Icon path={mdiTag} size={18} className={'mr-1'} color="#00A5A8" /> <b>Target Market Information</b>
              </h2>
              <hr />
              <div className="mb-3">
                <label htmlFor="state">
                  Target State:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <select
                  onChange={e => {
                    setState(e.currentTarget.value);
                  }}
                  value={state}
                  className="form-select"
                  name="State"
                >
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="City">
                  Target City:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  type="text"
                  onChange={e => setCity(e.currentTarget.value)}
                  className="form-control"
                  id="City"
                  name="City"
                  value={city}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="state">
                  Site Template:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <div className="row position-relative">
                  <div className="col">
                    <select
                      onChange={e => {
                        setCurrentViewSiteTemplateBTN(viewSiteTemplateBTN(e.currentTarget.value as unknown as number));
                        setCurrentSiteTemplateId(e.currentTarget.value as unknown as number);
                      }}
                      value={currentSiteTemplateId}
                      className="form-select"
                      name="State"
                    >
                      <option value={-1}>Please Select Template</option>
                      {siteTemplates.map((siteTemplate, index) => (
                        <option value={siteTemplate?.id} key={index}>
                          {siteTemplate?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-auto">{currentViewSiteTemplateBTN}</div>
                  {isLoadSiteTemplates && <FullLoader width="0.75rem" />}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="state">
                  Video Template:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <div className="row position-relative">
                  <div className="col">
                    <select
                      onChange={e => {
                        setCurrentViewVideoTemplateBTN(viewVideoTemplateBTN(e.currentTarget.value as unknown as number));
                        setCurrentVideoTemplateId(e.currentTarget.value as unknown as number);
                      }}
                      value={currentVideoTemplateId}
                      className="form-select"
                      name="State"
                    >
                      <option value={-1}>Please Select Video Template</option>
                      {videoTemplates.map((videoTemplate, index) => (
                        <option value={videoTemplate?.Id} key={index}>
                          {videoTemplate?.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-auto">{currentViewVideoTemplateBTN}</div>
                  {isLoadVideoTemplates && <FullLoader width="0.75rem" />}
                </div>
              </div>

              <div className="row">
                <div className="col text-center">
                  <button
                    disabled={isCanCreateSite()}
                    style={{ backgroundColor: 'rgb(22, 211, 154)', color: '#fff' }}
                    onClick={createAndClose}
                    className="btn m-2"
                  >
                    Create Site
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/* Edit Site Modal */}

      <Modal title={'Edit Site'} scroll={true} visibility={isSiteEditModal} xl={true} closeFunc={SiteCreateModalClose}>
        <div style={{ position: 'relative' }}>
          <form onSubmit={e => e.preventDefault()} className="needs-validation">
            <div>
              <h2 className="admin-box-title mb-2">
                <Icon path={mdiTag} size={18} className={'mr-1'} color="#00A5A8" /> <b>Site Info</b>
              </h2>
              <hr />
              <div className="mb-3">
                <label htmlFor="CompanyName">
                  Company Name:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  onChange={e => {
                    setCompanyName(e.currentTarget.value);
                  }}
                  type="text"
                  className={`form-control`}
                  name="CompanyName"
                  value={companyName}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="DomainName">Domain Name:</label>
                <div className="input-group">
                  <input type="text" readOnly className="form-control" name="DomainName" value={domainName} />
                  <span className="input-group-text">.dealmanagerpro.com</span>
                </div>
              </div>

              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="CustomDomainName">
                  Custom Domain Name
                  <IconButton style={{paddingRight: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: '2px'}} color='primary' onClick={(e)=>{setAnchorEl(e.currentTarget)}}>
                      <HelpOutlineIcon fontSize="small"/>
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => {setAnchorEl(null);}}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                        <Box style={{padding: '10px'}}>
                          <Typography variant="h6" gutterBottom style={{ display: 'block' }}>
                            For the use of a custom domain, you need:
                          </Typography>
                          <Typography variant="subtitle1"gutterBottom>
                            1. Buy or already have a domain;
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            2. In the DNS settings of your domain you need to create a CNAME record:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <div>- <span className='popover-bold'>Type</span> - CNAME;</div>
                            <div>- <span className='popover-bold'>Name</span> - is your custom domain. It must start with the www;</div>
                            <div>- <span className='popover-bold'>Target</span> - use the address from the "Domain Name" field above: <span className='popover-bold'>{domainName ? domainName:'some-name'}.dealmanagerpro.com;</span></div>
                            <div>- <span className='popover-bold'>TTL</span> - 60 sec. or Auto;</div>
                            <div>- <span className='popover-bold'>Proxy</span> - if there is a proxy parameter, turn it off.</div>
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            3. Wait a few minutes while DNS registration record in network 
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <div className='popover-bold'>Example:</div>
                            <div>For www.custom.mydomain.com in DNS settings →</div>
                            <table className='popover-table'>
                              <tbody>
                                <tr>
                                  <td>Type</td>
                                  <td>Name</td>
                                  <td>Target</td>
                                  <td>TTL</td>
                                </tr>
                                <tr>
                                  <td>CNAME</td>
                                  <td className='popover-bold'>www.custom.mydomain.com</td>
                                  <td className='popover-styled-cell' >{domainName ? domainName:'some-name'}.dealmanagerpro.com</td>
                                  <td>60 sec.</td>
                                </tr>
                              </tbody>
                            </table>
                          </Typography>
                        </Box>
                    </Popover>
                  </label>
                  <span style={{ fontSize: '0.8rem', alignSelf: 'flex-end' }}>
                    Only letters in lowercase, numbers and hyphens are allowed
                  </span>
                </div>
                <div className="position-relative">
                  <div className="input-group">
                    <span className="input-group-text">www.</span>
                    <input
                      onBlur={customDomainBlur}
                      onChange={e => {
                        const domainWithoutSpaces = e.currentTarget.value
                          .toLowerCase()
                          .replaceAll(/[^A-Za-z0-9-.]/gm, '');
                        setCustomDomainName(domainWithoutSpaces);
                      }}
                      type="text"
                      className={`form-control ${(customDomainName.length > 0 &&
                          /[^A-Za-z0-9]/.test(customDomainName[customDomainName.length - 1])) ||
                        /[^A-Za-z0-9]/.test(customDomainName[0])
                          ? '--invalid'
                          : ''
                      }`}
                      name="CustomDomainName"
                      value={customDomainName}
                    />
                  </div>
                </div>
                {customDomainWarnings && (
                  <div
                    style={{
                      textAlign: 'center',
                      background: '#f05e5e',
                      color: '#fff',
                      borderRadius: '0.25rem',
                      verticalAlign: 'middle',
                      fontSize: '0.8rem',
                      padding: '0.25rem',
                      marginTop: '0.25rem',
                    }}
                  >
                    {customDomainWarnings}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email">
                  Email:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  type="text"
                  onChange={e => setEmail(e.currentTarget.value)}
                  className={`form-control ${validateEmail(email) || !email?.trim() ? '' : '--invalid'}`}
                  name="email"
                  value={email}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone">
                  Phone Number:
                  <span style={{ color: '#f00' }}> * </span>
                </label>

                <InputMask
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setPhoneNumber(e.currentTarget.value);
                  }}
                  type="tel"
                  name="phone"
                  mask="(999)-999-9999"
                  className={`form-control ${!phoneNumber.includes('_') ? '' : '--invalid'}`}
                  maskPlaceholder="_"
                  value={phoneNumber}
                />
              </div>
              <h2 className="admin-box-title mb-2">
                <Icon path={mdiTag} size={18} className={'mr-1'} color="#00A5A8" /> <b>Target Market Information</b>
              </h2>
              <hr />
              <div className="mb-3">
                <label htmlFor="state">
                  State:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <select
                  onChange={e => {
                    setState(e.currentTarget.value);
                  }}
                  value={state}
                  className="form-select"
                  name="State"
                >
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="City">
                  City:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  type="text"
                  onChange={e => setCity(e.currentTarget.value)}
                  className="form-control"
                  id="City"
                  name="City"
                  value={city}
                />
              </div>
              {!!videoId &&
                <div className="mb-3">
                  <label htmlFor="state">
                    Video Template:
                    <span style={{ color: '#f00' }}> * </span>
                  </label>
                  <div className="row position-relative">
                    <div className="col">
                      <select
                        onChange={e => {
                          setVideoId(e.currentTarget.value as unknown as number );
                        }}
                        value={videoId}
                        className="form-select"
                        name="Url"
                      >
                        <option value={-1}>Please Select Video Template</option>
                        {videoTemplates.map((videoTemplate, index) => (
                          <option value={videoTemplate?.Id} key={index}>
                            {videoTemplate?.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-auto">{viewVideoTemplateBTN(videoId)}</div>
                    {isLoadVideoTemplates && <FullLoader width="0.75rem" />}
                  </div>
                </div>
              }
              <div className="row">
                <div className="col text-center">
                  <button
                    disabled={
                      !companyName?.trim() ||
                      !validateEmail(email) ||
                      !phoneNumber?.trim() ||
                      phoneNumber.includes('_') ||
                      /[^A-Za-z0-9]/.test(customDomainName[0]) ||
                      /[^A-Za-z0-9]/.test(customDomainName[customDomainName.length - 1]) ||
                      !city?.trim() ||
                      isLoadingCustomDomain ||
                      !!customDomainWarnings?.trim() ||
                      videoId === (-1)
                    }
                    onClick={editSiteAndRefresh}
                    style={{
                      color: '#fff',
                      backgroundColor: 'rgb(45, 206, 227)',
                      borderColor: 'rgb(34, 194, 220)',
                    }}
                    className="btn m-2"
                  >
                    Edit Site
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/*Delete Site*/}

      <Modal title={'Delete site'} visibility={isSiteDeleteModal} closeFunc={SiteCreateModalClose}>
        <div style={{ position: 'relative' }}>
          <h5 className="text-center">Are you sure you want to delete this site?</h5>
          <div className="row">
            <div className="col text-center">
              <button
                onClick={deleteAndRefresh}
                style={{
                  color: '#fff',
                  backgroundColor: 'rgb(255, 117, 136)',
                  borderColor: 'rgb(255, 98, 117)',
                }}
                className="btn m-2"
              >
                Delete Site
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MySites;
