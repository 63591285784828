import { toast } from 'react-toastify';
import { authFetch, updateFetch } from '../myFetches';

const WpSettings = {
  getWpPluginsByPage: (
    SearchString: string | undefined,
    SortOrder: string | undefined,
    SortType: string | undefined,
    PageNumber: number,
    NumberOfPlugins: number,
    statusCodeHandle?: (status: number) => {}
  ) =>
    authFetch(window.location.origin + '/api/Admin/wpService/getAllPlugins', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        SearchString: SearchString,
        SortOrder: SortOrder,
        SortType: SortType,
        PageNumber: PageNumber,
        NumberOfRecord: NumberOfPlugins,
      }),
    }).then(async response => {
      try {
        if (statusCodeHandle) {
          statusCodeHandle(response.status);
        }
        if (response.status !== 200) {
          return;
        }
        const result = await response.json();
        if (result.isSuccessful && result.data && result.data.Plugins) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
        } else {
          return 0;
        }
      } catch (e) {
        console.log('Error. getWpPluginsByPage: ' + e);
        toast.error('Unexpected error');
      }
    }),

  checkExistingPlugin: async (PluginName: string, statusCodeHandle?: (status: number) => void) => {
    const request = await authFetch(window.location.origin + '/api/Admin/wpService/checkIsPluginExist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(PluginName),
    });

    if (request.status !== 200) {
      statusCodeHandle && statusCodeHandle(request.status);
      return;
    }

    return (await request.json()) as { isSuccessful: boolean; errors: string[] } | undefined;
  },

  addPlugin: ({
    Name,
    ShoulBeInstalled,
    callback,
    statusCodeHandle,
  }: {
    Name: string;
    ShoulBeInstalled: boolean;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/wpService/addPlugin',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Name,
          ShoulBeInstalled,
        }),
      },
      toastTitle: `Adding Plugin`,
      toastSuccess: 'Plugin was added',
      callback,
      statusCodeHandle,
    });
  },

  editPlugin: ({
    Id,
    Name,
    ShouldBeInstalled,
    callback,
    statusCodeHandle,
  }: {
    Id: string | number;
    Name: string;
    ShouldBeInstalled: boolean;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/wpService/updatePlugin',
      options: {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Id,
          Name,
          ShouldBeInstalled,
        }),
      },
      toastTitle: `Editing Plugin`,
      toastSuccess: 'Plugin was edited',
      callback,
      statusCodeHandle,
    });
  },

  deletePlugin: ({
    Id,
    callback,
    statusCodeHandle,
  }: {
    Id: string | number;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/wpService/removePlugin',
      options: {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: Id.toString(),
      },
      toastTitle: `Deleting Plugin`,
      toastSuccess: 'Plugin was deleted',
      callback,
      statusCodeHandle,
    });
  },

  getVideoUrlsByPage: (
    SearchString: string | undefined,
    SortOrder: string | undefined,
    SortType: string | undefined,
    PageNumber: number,
    NumberOfPlugins: number,
    statusCodeHandle?: (status: number) => {}
  ) =>
    authFetch(window.location.origin + '/api/Admin/videoTemplate/getByPage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        SearchString: SearchString,
        SortOrder: SortOrder,
        SortType: SortType,
        PageNumber: PageNumber,
        NumberOfRecord: NumberOfPlugins,
      }),
    }).then(async response => {
      try {
        if (statusCodeHandle) {
          statusCodeHandle(response.status);
        }
        if (response.status !== 200) {
          return;
        }
        const result = await response.json();
        if (result.isSuccessful && result.data && result.data.VideoUrls) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
        } else {
          return 0;
        }
      } catch (e) {
        console.log('Error. getTemplatesByPage: ' + e);
        toast.error('Unexpected error');
      }
    }),

    getTemplatesByPage: (
      SearchString: string | undefined,
      SortOrder: string | undefined,
      SortType: string | undefined,
      PageNumber: number,
      NumberOfPlugins: number,
      statusCodeHandle?: (status: number) => {}
    ) =>
      authFetch(window.location.origin + '/api/Admin/template/getByPage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          SearchString: SearchString,
          SortOrder: SortOrder,
          SortType: SortType,
          PageNumber: PageNumber,
          NumberOfRecord: NumberOfPlugins,
        }),
      }).then(async response => {
        try {
          if (statusCodeHandle) {
            statusCodeHandle(response.status);
          }
          if (response.status !== 200) {
            return;
          }
          const result = await response.json();
          if (result.isSuccessful && result.data && result.data.Templates) {
            return result.data;
          } else if (result.errors.length > 0) {
            result.errors.forEach((error: string) => toast.error(error));
          } else {
            return 0;
          }
        } catch (e) {
          console.log('Error. getTemplatesByPage: ' + e);
          toast.error('Unexpected error');
        }
      }),

  createTemplate: ({
    Title,
    Domain,
    Url,
    Description,
    DbName,
    PathToFiles,
    callback,
    statusCodeHandle,
  }: {
    Title: string,
    Domain: string,
    Url: string,
    Description: string,
    DbName: string,
    PathToFiles: string,
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/template/create',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Title,
          Domain,
          Url,
          Description,
          DbName,
          PathToFiles,
        }),
      },
      toastTitle: `Adding Template`,
      toastSuccess: 'Template was added',
      callback,
      statusCodeHandle,
    });
  },

  createVideo: ({
    Url,
    Name,
    callback,
    statusCodeHandle,
  }: {
    Url: string;
    Name: string;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/videoTemplate/create',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Url,
          Name,
        }),
      },
      toastTitle: `Adding Video URL`,
      toastSuccess: 'Video URL was added',
      callback,
      statusCodeHandle,
    });
  },

  editTemplate: ({
    Id,
    Title,
    Domain,
    Url,
    Description,
    DbName,
    PathToFiles,
    callback,
    statusCodeHandle,
  }: {
    Id: string | number;
    Title: string;
    Domain: string;
    Url: string;
    Description: string;
    DbName: string;
    PathToFiles: string;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/template/update',
      options: {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Id,
          Title,
          Domain,
          Url,
          Description,
          DbName,
          PathToFiles,
        }),
      },
      toastTitle: `Editing Template`,
      toastSuccess: 'Template was edited',
      callback,
      statusCodeHandle,
    });
  },

  editVideo: ({
    Id,
    Url,
    Name,
    callback,
    statusCodeHandle,
  }: {
    Id: string | number;
    Url: string;
    Name: string;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/videoTemplate/update',
      options: {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Id,
          Url,
          Name,
        }),
      },
      toastTitle: `Editing Video URL`,
      toastSuccess: 'Video URL was edited',
      callback,
      statusCodeHandle,
    });
  },

  deleteTemplate: ({
    Id,
    callback,
    statusCodeHandle,
  }: {
    Id: string | number;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/template/delete',
      options: {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Id,
        }),
      },
      toastTitle: `Deleting Template`,
      toastSuccess: 'Template was deleted',
      callback,
      statusCodeHandle,
    });
  },

  deleteVideo: ({
    Id,
    callback,
    statusCodeHandle,
  }: {
    Id: string | number;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/Admin/videoTemplate/delete',
      options: {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          Id,
        }),
      },
      toastTitle: `Deleting Video URL`,
      toastSuccess: 'Video URL was deleted',
      callback,
      statusCodeHandle,
    });
  },
};

export default WpSettings;
