import { push } from 'connected-react-router';
import { AppThunkAction, LoginModel } from '../../types/account';
import { AccountActions } from '../actions/accountActions';
import * as accountRequests from '../../api/accountRequests';
import { store } from '../store';
import { toast } from 'react-toastify';

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const accountThunks = {
  login:
    (
      account: LoginModel,
      statusCb: { load: () => void; loaded: () => void; success?: () => void; failed?: () => void }
    ): AppThunkAction<AccountActions> =>
    dispatch => {
      if (!account.email.trim()) {
        toast.error('Please, type in email');
        return;
      }
      if (!account.password) {
        toast.error('Please, type in password');
        return;
      }
      if (!validateEmail(account.email)) {
        toast.error('Please, type in correct email');
        return;
      }

      statusCb.load();
      accountRequests
        .login(account)
        .then(response => response.json())
        .then(res => {
          statusCb.loaded();
          if (res.isSuccessful && res.data) {
            const { Token, AllRoles: roles } = res.data as { Token: string; AllRoles: string[] };
            dispatch({ type: 'user/login-success', token: Token, roles });
            statusCb.success && statusCb.success();
            dispatch(push('/'));
          } else {
            statusCb.failed && statusCb.failed();
            toast.error('Email or password is incorrect');
          }
        });
    },
  logout: (): AppThunkAction<AccountActions> => dispatch => {
    accountRequests.logout().then(e => {
      if (e.ok) {
        console.log(e.status);
        dispatch({ type: 'user/logout' });
        dispatch(push('/login'));
      } else {
        console.log('fail!');
      }
    });
  },
  redirectedLogin:
    (
      token: string,
      handleStatusCode?: (status: number) => {},
      cb?: { resolve?: () => void; reject?: () => void }
    ): AppThunkAction<AccountActions> =>
    async dispatch => {
      const response = await fetch(window.location.origin + '/api/account/validateToken', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ AuthToken: token }),
      });
      if (response.status !== 200) {
        handleStatusCode && handleStatusCode(response.status);
      }
      const result = await response.json();

      if (result) {
        dispatch({ type: 'user/login-success', token, roles: ['ADMIN', 'USER'] });
        dispatch(push('/'));
      } else {
        if (store.getState().account.isAuthenticated) {
          const response = await accountRequests.logout();
          if (!response.ok) {
            handleStatusCode && handleStatusCode(response.status);
          }
        }
        dispatch({ type: 'user/logout' });
        dispatch(push('/login'));
      }
    },
};

export type AccountThunks = typeof accountThunks;
export default accountThunks;
