import { toast } from 'react-toastify';
import { authFetch, updateFetch } from './myFetches';

const usersRequest = {
  getSiteTemplate: (statusCodeHandle?: (status: number) => {}) =>
    authFetch(window.location.origin + '/api/WordpressSite/getSiteTemplates', {
      method: 'POST',
    }).then(async response => {
      if (statusCodeHandle) {
        statusCodeHandle(response.status);
      }
      if (response.status !== 200) {
        return;
      }
      try {
        const result = await response.json();
        if (result.isSuccessful && result.data) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
          return [];
        } else {
          return [];
        }
      } catch (e) {
        console.log('Error. getNumberOfSites: ' + e);
        toast.error('Unexpected error');
      }
    }),
    getVideoTemplate: (statusCodeHandle?: (status: number) => {}) =>
    authFetch(window.location.origin + '/api/WordpressSite/getVideoTemplates', {
      method: 'POST',
    }).then(async response => {
      if (statusCodeHandle) {
        statusCodeHandle(response.status);
      }
      if (response.status !== 200) {
        return;
      }
      try {
        const result = await response.json();
        if (result.isSuccessful && result.data) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
          return [];
        } else {
          return [];
        }
      } catch (e) {
        console.log('Error. getNumberOfSites: ' + e);
        toast.error('Unexpected error');
      }
    }),
  getSitesByPage: (
    SearchString: string | undefined,
    SortOrder: string | undefined,
    SortType: string | undefined,
    PageNumber: number,
    NumberOfRecords: number,
    statusCodeHandle?: (status: number) => {}
  ) =>
    authFetch(window.location.origin + '/api/WordpressSite/getSitesByPage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        SearchString: SearchString,
        SortOrder: SortOrder,
        SortType: SortType,
        PageNumber: PageNumber,
        NumberOfRecord: NumberOfRecords,
      }),
    }).then(async response => {
      if (statusCodeHandle) {
        statusCodeHandle(response.status);
      }
      if (response.status !== 200) {
        return;
      }
      try {
        const result = await response.json();
        if (
          result.isSuccessful &&
          result.data &&
          'WordpressSites' in result.data &&
          'TotalSitesForUser' in result.data
        ) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
          return [];
        } else {
          return [];
        }
      } catch (e) {
        console.log('Error. getNumberOfSites: ' + e);
        toast.error('Unexpected error');
      }
    }),
  createSite: async (
    currentSiteTemplateId: number,
    currentVideoTemplateId: number,
    CompanyName: string,
    DomainName: string,
    CustomDomainName: string,
    Email: string,
    Phone: string,
    State: string,
    City: string,
    cb: () => {},
    statusCodeHandle?: (status: number) => {}
  ) => {
    updateFetch({
      link: window.location.origin + '/api/WordpressSite/createWPSite',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          SiteTemplateId: currentSiteTemplateId,
          VideoUrlId : currentVideoTemplateId,
          CompanyName: CompanyName,
          DomainName: DomainName,
          CustomDomainName: CustomDomainName,
          Email: Email,
          Phone: Phone,
          State: State,
          City: City,
        }),
      },
      toastTitle: 'Creating your site. This can take a few minutes. Please do not leave this page.',
      toastSuccess: 'Site was created successfully',
      callback: cb,
      statusCodeHandle,
      isBigMessage:true
    });
  },
  deleteSite: (wpId: string | number, cb: () => {}, statusCodeHandle?: (status: number) => {}) => {
    updateFetch({
      link: window.location.origin + '/api/WordpressSite/deleteWPSite',
      options: {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ WordpressSiteId: wpId }),
      },
      toastTitle: 'Site is deleting',
      toastSuccess: 'Site was deleted successfully',
      callback: cb,
      statusCodeHandle,
    });
  },
  updateSite: ({
    videoId,
    wpSiteId,
    companyName,
    customDomain = '',
    phoneNumber,
    email,
    city,
    state,
    callback,
    statusCodeHandle,
  }: {
    videoId:string | number;
    wpSiteId: string | number;
    companyName: string;
    customDomain: string;
    phoneNumber: string;
    email: string;
    city: string;
    state: string;
    callback: () => {};
    statusCodeHandle?: (status: number) => {};
  }) => {
    updateFetch({
      link: window.location.origin + '/api/WordpressSite/updateWPSite',
      options: {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          VideoUrlId: videoId,
          Id: wpSiteId,
          CompanyName: companyName,
          CustomDomainName: customDomain,
          Phone: phoneNumber,
          Email: email,
          State: state,
          City: city,
        }),
      },
      toastTitle: 'Site is editing',
      toastSuccess: 'Site was edited',
      statusCodeHandle,
      callback,
    });
  },
  IsHasDefaultDomain: async ({
    DefaultDomainName,
    statusCodeHandle,
  }: {
    DefaultDomainName: string;
    statusCodeHandle?: (status: number) => {};
  }) => {
    const response = await authFetch('/api/WordpressSite/IsHasDomain', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        DefaultDomainName: DefaultDomainName + '.dealmanagerpro.com',
        CustomDomainName: '',
      }),
    });
    statusCodeHandle && statusCodeHandle(response.status);
    if (response.status === 401) return;

    const result = await response.json();
    return result;
  },
  IsCustomNameCorrespond: async ({
    DefaultDomainName,
    CustomDomainName,
    statusCodeHandle,
  }: {
    DefaultDomainName: string;
    CustomDomainName: string;
    statusCodeHandle?: (status: number) => {};
  }) => {
    const response = await authFetch('/api/WordpressSite/IsCustomDomainCnameCorrespondToDefaultDomain', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        DefaultDomainName: DefaultDomainName + '.dealmanagerpro.com',
        CustomDomainName: 'www.' + CustomDomainName,
      }),
    });
    statusCodeHandle && statusCodeHandle(response.status);
    if (response.status === 401) return;

    const result = await response.json();
    return result;
  },
};

export const getUsersByPage = (PageNumber: number, NumberOfUsers: number) =>
  authFetch(window.location.origin + '/api/Admin/getUsersByPage', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ PageNumber: PageNumber, NumberOfUsers: NumberOfUsers }),
  });

export default usersRequest;
