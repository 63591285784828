/* eslint-disable @typescript-eslint/no-unused-vars */
import MaterialTable from '@material-table/core';
import { Box, Button, IconButton, Popover, Typography } from '@material-ui/core';
import { mdiCertificateOutline, mdiClose, mdiEye, mdiPencil, mdiTag } from '@mdi/js';
import { useRef, useState } from 'react';
import { AllSitesRequests } from '../../api/adminRequests/index';
import InputMask from 'react-input-mask';
import { Icon } from '../../components/Icon';
import { TableCellStyle, TableHeaderStyle, TableOptions, ValidateEmail } from '../../Helpers/PagesCommonData';
import { tableIcons } from '../../Helpers/TableIcons';
import './AllSites.css';
import states from '../MySites/States';
import usersRequest from '../../api/usersRequests';
import Modal from '../../components/Modal/Modal';
import FullLoader from '../../components/Loader/FullLoader/FullLoader';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import React from 'react';

const AllSites = ({
  handleStatusCode,
}: React.PropsWithChildren<{
  handleStatusCode?: (status: number) => {};
}>) => {
  const allSitesTable = useRef<any>(null);
  const [isSiteDeleteModal, setIsSiteDeleteModal] = useState<boolean>(false);
  const [isSiteEditModal, setIsSiteEditModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [domainName, setDomainName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [videoId, setVideoId] = useState<number>(-1);
  const [state, setState] = useState<string>(states[0]);
  const [city, setCity] = useState<string>('');
  const [wpSiteId, setWpSiteId] = useState<string | number>('');
  const [customDomainName, setCustomDomainName] = useState<string>('');
  const [videoTemplates, setVideoTemplates] = useState<any[]>([]);
  const [isLoadVideoTemplates, setIsLoadVideoTemplates] = useState<boolean>(false);

  const [firstPhoneNumber, setFirstPhoneNumber] = useState<string>('');
  const [firstCustomDomainName, setFirstCustomDomainName] = useState<string>('');
  const [firstCompanyName, setFirstCompanyName] = useState<string>('');
  const [firstEmail, setFirstEmail] = useState<string>('');
  const [firstCity, setFirstCity] = useState<string>('');
  const [firstState, setFirstState] = useState<string>(states[0]);
  const [firstVideo, setFirstVideo] = useState<number>(-1);

  const [customDomainWarnings, setCustomDomainWarnings] = useState<string>('');

  const [isLoadingCustomDomain, setIsLoadingCustomDomain] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const openSiteEditModal = (
    selectedVideoId: number,
    wpSiteId: string,
    companyName: string,
    domainName: string,
    phoneNumber: string,
    state: string,
    city: string,
    email: string,
    customDomainName: string
  ) => {
    const realCustomDomain = customDomainName
      .split('www.')
      .slice(1)
      .join('')
      .replaceAll(/[^A-Za-z0-9-.]/gm, '');
    let splitDomain = domainName.split('.dealmanagerpro.com');
    const defaultDomain = splitDomain.slice(0, splitDomain.length - 1).join('');
    setFirstCompanyName(companyName);
    setFirstPhoneNumber(phoneNumber);
    setFirstState(state);
    setFirstCity(city);
    setFirstEmail(email);
    setFirstCustomDomainName(realCustomDomain);
    setFirstVideo(selectedVideoId);

    setCompanyName(companyName);
    setDomainName(defaultDomain);
    setPhoneNumber(phoneNumber);
    setState(state);
    setCity(city);
    setWpSiteId(wpSiteId);
    setEmail(email);
    setIsSiteEditModal(true);
    setCustomDomainName(realCustomDomain);
    disabledScrollOnBody(true);
    setVideoId(selectedVideoId);
    loadVideoTemplates();
  };

  const disabledScrollOnBody = (isDisable: boolean) => {
    if(isDisable){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'unset';
    }
  }

  const deleteAndRefresh = () => {
    usersRequest.deleteSite(
      wpSiteId,
      updateTable,
      handleStatusCode
    );
    setWpSiteId(0);
    setIsSiteDeleteModal(false);
  };

  const viewVideoTemplateBTN = (selectedVideoId: number) => {
    const url = videoTemplates.find(element => element?.Id === selectedVideoId)?.Url;
    return (
      <Button
        disabled={selectedVideoId === -1}
        variant="contained"
        style={{
          color: '#fff',
          borderRadius: '.21rem',
          backgroundColor: '#16D39A',
          borderColor: '#10C888',
          minWidth: '0',
          marginRight: '0.25rem',
          padding: 0,
        }}
      >
        <a
          style={{ color: 'inherit', textDecoration: 'none', padding: '0.25rem 0.5rem' }}
          href={url}
          rel="noreferrer noopener"
          target="_blank"
        >
          <Icon size={18} path={mdiEye} />
        </a>
      </Button>
    );
  };

  const loadVideoTemplates = async () => {
    setIsLoadVideoTemplates(true);
    const videos = await usersRequest.getVideoTemplate(handleStatusCode);
    setVideoTemplates(videos);
    setIsLoadVideoTemplates(false);
  };

  const customDomainBlur = async () => {
    if (customDomainName?.trim()) {
      if (domainName?.trim()) {
        setIsLoadingCustomDomain(true);
        const customResult = await usersRequest.IsCustomNameCorrespond({
          DefaultDomainName: domainName,
          CustomDomainName: customDomainName,
          statusCodeHandle: handleStatusCode,
        });
        if (!customResult?.isSuccessful) {
          setCustomDomainWarnings(customResult?.message ?? '');
        } else setCustomDomainWarnings('');
        setIsLoadingCustomDomain(false);
      }
    } else {
      setCustomDomainWarnings('');
    }
  };

  const SiteModalsClose = () => {
    setCompanyName('');
    setDomainName('');
    setState(states[0]);
    setPhoneNumber('');
    setCity('');
    setCustomDomainName('');
    setWpSiteId('');
    setEmail('');
    setIsSiteEditModal(false);
    setIsSiteDeleteModal(false);
    setCustomDomainWarnings('');
    disabledScrollOnBody(false);
    setVideoId(-1);
    setVideoTemplates([]);
  };

  const editSiteAndRefresh = () => {
    if (
      customDomainName !== firstCustomDomainName ||
      firstCity !== city ||
      firstPhoneNumber !== phoneNumber ||
      firstState !== state ||
      firstEmail !== email ||
      firstCompanyName !== companyName ||
      firstVideo !== videoId
    ) {
      usersRequest.updateSite({
        videoId,
        wpSiteId,
        companyName,
        customDomain: !customDomainName.toLowerCase()?.trim()
          ? ''
          : 'www.' + customDomainName.toLowerCase().replaceAll(/[^A-Za-z0-9-.]/gm, ''),
        phoneNumber: phoneNumber.replaceAll(/\(|\)|-/gm, ''),
        state,
        city,
        email,
        callback: updateTable,
        statusCodeHandle: handleStatusCode,
      });
    }
    SiteModalsClose();
  };

  const updateTable = () => allSitesTable && allSitesTable.current && allSitesTable.current.onQueryChange();
  const updateSiteById = (id: string | number, url: string) => AllSitesRequests.updateSiteById(id, url, updateTable);
  const updateSitesByInstanceId = (id: string | number, ip: string) =>
    AllSitesRequests.updateSiteByInstanceId(id, ip, updateTable);

  const PrettierDate = (date: string | Date) => {
    const dateObj = new Date(date);
    return (
      <span>
        {dateObj.getUTCMonth() + 1 < 10 ? `0${dateObj.getUTCMonth() + 1}` : dateObj.getUTCMonth() + 1}.
        {dateObj.getUTCDate() < 10 ? `0${dateObj.getUTCDate()}` : dateObj.getUTCDate()}.{+dateObj.getUTCFullYear()}{' '}
        {dateObj.getUTCHours() < 10 ? `0${dateObj.getUTCHours()}` : dateObj.getUTCHours()}:
        {dateObj.getUTCMinutes() < 10 ? `0${dateObj.getUTCMinutes()}` : dateObj.getUTCMinutes()}
      </span>
    );
  };

  return (
    <div className="AllSites">
      <div className="Sites-Header">
        <h3 className="Sites-Title">ALL SITES MANAGEMENT</h3>
      </div>
      <MaterialTable
        icons={tableIcons}
        style={{ position: 'relative', left: '0', right: '0' }}
        options={TableOptions as any}
        localization={{
          toolbar: {
            searchPlaceholder: '',
          },
        }}
        tableRef={allSitesTable}
        title={<h4 style={{ fontSize: '1.12rem', fontWeight: 'lighter' }}>CURRENT ACTIVE SITES</h4>}
        columns={[
          {
            field: 'Id',
            hidden: true,
          },
          {
            field: 'VideoUrlId',
            hidden: true,
          },
          {
            field: 'InstanceRecordId',
            hidden: true,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>User</span>,
            field: 'UserFullName',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.UserFullName}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Site</span>,
            field: 'FullDomainName',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => (
              <div className="table--full-width">
                {rowData.CustomDomainName && rowData.CustomDomainName?.trim()
                  ? rowData.CustomDomainName
                  : rowData.FullDomainName}
              </div>
            ),
          },
          {
            field: 'CustomDomainName',
            hidden: true,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Company Name</span>,
            field: 'CompanyName',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.CompanyName}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Target City</span>,
            field: 'City',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.City}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Target State</span>,
            field: 'State',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.State}</div>,
          },
          {
            field: 'Email',
            title: <span style={{ minWidth: 'max-content' }}>Email</span>,
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.Email}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Phone</span>,
            field: 'Phone',
            render: rowData => <div className="table--full-width">{rowData.Phone}</div>,
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
          },
          { field: 'URl', hidden: true },
          {
            title: <span style={{ minWidth: 'max-content' }}>Site Instance Id</span>,
            field: 'InstanceId',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.InstanceId}</div>,
          },
          {
            title: <span style={{ minWidth: 'max-content' }}>Instance Ip Address</span>,
            field: 'InstanceIp',
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => <div className="table--full-width">{rowData.InstanceIp}</div>,
          },
          // {
          //   title: <span style={{ minWidth: 'max-content' }}>SSL Expiration Date</span>,
          //   field: 'SslExpirationDate',
          //   cellStyle: date =>
          //     ({ ...TableCellStyle, backgroundColor: new Date(date) < new Date() && '#ee5c4c' } as any),
          //   headerStyle: TableHeaderStyle,
          //   render: rowData => <div className="table--full-width">{PrettierDate(rowData.SslExpirationDate)}</div>,
          // },
          {
            cellStyle: TableCellStyle,
            headerStyle: TableHeaderStyle,
            render: rowData => (
              <div style={{ minWidth: 'max-content' }}>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#2DCEE3',
                    borderColor: '#22C2DC',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  onClick={() =>
                    openSiteEditModal(
                      rowData.VideoUrlId,
                      rowData.Id,
                      rowData.CompanyName,
                      rowData.FullDomainName,
                      rowData.Phone,
                      rowData.State,
                      rowData.City,
                      rowData.Email,
                      rowData.CustomDomainName
                    )
                  }
                >
                  <Icon size={18} path={mdiPencil} />
                </Button>
                {/* <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#16D39A',
                    borderColor: '#22C2DC',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  disabled={new Date(rowData.SslExpirationDate) > new Date()}
                  onClick={() =>
                    rowData.CustomDomainName?.trim()
                      ? updateSiteById(rowData.Id, rowData.CustomDomainName)
                      : updateSitesByInstanceId(rowData.InstanceRecordId, rowData.InstanceIp)
                  }
                >
                  <Icon size={18} path={mdiCertificateOutline} />
                </Button> */}
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#16D39A',
                    borderColor: '#10C888',
                    minWidth: '0',
                    marginRight: '0.25rem',
                    padding: 0,
                  }}
                >
                  <a
                    style={{
                      color: 'inherit',
                      textDecoration: 'none',
                      padding: '0.25rem 0.5rem',
                    }}
                    href={
                      rowData.CustomDomainName
                        ? !rowData.CustomDomainName?.trim()
                          ? rowData.URl
                          : 'https://' + rowData.CustomDomainName
                        : rowData.URl
                    }
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    <Icon size={18} path={mdiEye} />
                  </a>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: '#fff',
                    borderRadius: '.21rem',
                    backgroundColor: '#FF7588',
                    borderColor: '#FF6275',
                    padding: '0.36rem 0.5rem',
                    minWidth: '0',
                    marginRight: '0.25rem',
                  }}
                  onClick={() => {
                    setIsSiteDeleteModal(true);
                    setWpSiteId(rowData.Id);
                  }}
                >
                  <Icon size={18} path={mdiClose} />
                </Button>
              </div>
            ),
          },
        ]}
        data={query =>
          new Promise(async resolve => {
            const records = await AllSitesRequests.getAllSitesByPage(
              query?.search,
              query?.orderBy?.field,
              query?.orderDirection,
              query?.page,
              query?.pageSize,
              handleStatusCode
            ).then(response => {
              return response;
            });
            resolve({
              data: records ? (records.WordpressSites as never[]) : [],
              page: records ? (records?.PageNumber as number) : 0,
              totalCount: records ? (records?.NumberOfAllWordpressSites as number) : 0,
            });
          })
        }
      />
      {/* Edit Site Modal */}

      <Modal title={'Edit Site'} scroll={true} visibility={isSiteEditModal} xl={true} closeFunc={SiteModalsClose}>
        <div style={{ position: 'relative' }}>
          <form onSubmit={e => e.preventDefault()} className="needs-validation">
            <div>
              <h2 className="admin-box-title mb-2">
                <Icon path={mdiTag} size={18} className={'mr-1'} color="#00A5A8" /> <b>Site Info</b>
              </h2>
              <hr />
              <div className="mb-3">
                <label htmlFor="CompanyName">
                  Company Name:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  onChange={e => {
                    setCompanyName(e.currentTarget.value);
                  }}
                  type="text"
                  className={`form-control`}
                  name="CompanyName"
                  value={companyName}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="DomainName">Domain Name:</label>
                <div className="input-group">
                  <input type="text" readOnly className="form-control" name="DomainName" value={domainName} />
                                  <span className="input-group-text">.dealmanagerpro.com</span>
                </div>
              </div>

              <div className="mb-3">
                <div className="d-flex justify-content-between">
                  <label htmlFor="CustomDomainName">
                    Custom Domain Name
                    <IconButton style={{paddingRight: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: '2px'}} color='primary' onClick={(e)=>{setAnchorEl(e.currentTarget)}}>
                      <HelpOutlineIcon fontSize="small"/>
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => {setAnchorEl(null);}}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}>
                        <Box style={{padding: '10px'}}>
                          <Typography variant="h6" gutterBottom style={{ display: 'block' }}>
                            For the use of a custom domain, you need:
                          </Typography>
                          <Typography variant="subtitle1"gutterBottom>
                            1. Buy or already have a domain;
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            2. In the DNS settings of your domain you need to create a CNAME record:
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <div>- <span className='popover-bold'>Type</span> - CNAME;</div>
                            <div>- <span className='popover-bold'>Name</span> - is your custom domain. It must start with the www;</div>
                            <div>- <span className='popover-bold'>Target</span> - use the address from the "Domain Name" field above: <span className='popover-bold'>{domainName ? domainName:'some-name'}.dealmanagerpro.com</span></div>
                            <div>- <span className='popover-bold'>TTL</span> - 60 sec. or Auto.</div>
                            <div>- <span className='popover-bold'>Proxy</span> - if there is a proxy parameter, turn it off.</div>
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                            3. Wait a few minutes while DNS registration record in network 
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            <div className='popover-bold'>Example:</div>
                            <div>For www.custom.mydomain.com in DNS settings →</div>
                            <table className='popover-table'>
                              <tbody>
                                <tr>
                                  <td>Type</td>
                                  <td>Name</td>
                                  <td>Target</td>
                                  <td>TTL</td>
                                </tr>
                                <tr>
                                  <td>CNAME</td>
                                  <td className='popover-bold'>www.custom.mydomain.com</td>
                                  <td className='popover-styled-cell' >{domainName ? domainName:'some-name'}.dealmanagerpro.com</td>
                                  <td>60 sec.</td>
                                </tr>
                              </tbody>
                            </table>
                          </Typography>
                        </Box>
                    </Popover>
                  </label>
                  <span style={{ fontSize: '0.8rem', alignSelf: 'flex-end' }}>
                    Only letters in lowercase, numbers and hyphens are allowed
                  </span>
                </div>
                <div className="position-relative">
                  <div className="input-group">
                    <span className="input-group-text">www.</span>
                    <input
                      onBlur={customDomainBlur}
                      onChange={e => {
                        const domainWithoutSpaces = e.currentTarget.value
                          .toLowerCase()
                          .replaceAll(/[^A-Za-z0-9-.]/gm, '');
                        setCustomDomainName(domainWithoutSpaces);
                      }}
                      type="text"
                      className={`form-control ${
                        (customDomainName.length > 0 &&
                          /[^A-Za-z0-9]/.test(customDomainName[customDomainName.length - 1])) ||
                        /[^A-Za-z0-9]/.test(customDomainName[0])
                          ? '--invalid'
                          : ''
                      }`}
                      name="CustomDomainName"
                      value={customDomainName}
                    />
                  </div>
                </div>
                {customDomainWarnings && (
                  <div
                    style={{
                      textAlign: 'center',
                      background: '#f05e5e',
                      color: '#fff',
                      borderRadius: '0.25rem',
                      verticalAlign: 'middle',
                      fontSize: '0.8rem',
                      padding: '0.25rem',
                      marginTop: '0.25rem',
                    }}
                  >
                    {customDomainWarnings}
                  </div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="email">
                  Email:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  type="text"
                  onChange={e => setEmail(e.currentTarget.value)}
                  className={`form-control ${ValidateEmail(email) || !email?.trim() ? '' : '--invalid'}`}
                  name="email"
                  value={email}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phone">
                  Phone Number:
                  <span style={{ color: '#f00' }}> * </span>
                </label>

                <InputMask
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setPhoneNumber(e.currentTarget.value);
                  }}
                  type="tel"
                  name="phone"
                  mask="(999)-999-9999"
                  className={`form-control ${!phoneNumber.includes('_') ? '' : '--invalid'}`}
                  maskPlaceholder="_"
                  value={phoneNumber}
                />
              </div>
              <h2 className="admin-box-title mb-2">
                <Icon path={mdiTag} size={18} className={'mr-1'} color="#00A5A8" /> <b>Target Market Information</b>
              </h2>
              <hr />
              <div className="mb-3">
                <label htmlFor="state">
                  State:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <select
                  onChange={e => {
                    setState(e.currentTarget.value);
                  }}
                  value={state}
                  className="form-select"
                  name="State"
                >
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="City">
                  City:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <input
                  type="text"
                  onChange={e => setCity(e.currentTarget.value)}
                  className="form-control"
                  id="City"
                  name="City"
                  value={city}
                />
              </div>
              {!!videoId &&
              <div className="mb-3">
                <label htmlFor="state">
                  Video Template:
                  <span style={{ color: '#f00' }}> * </span>
                </label>
                <div className="row position-relative">
                  <div className="col">
                    <select
                      onChange={e => {
                        setVideoId(e.currentTarget.value as unknown as number )
                      }}
                      value={videoId}
                      className="form-select"
                      name="Url"
                    >
                      <option value={-1}>Please Select Video Template</option>
                      {videoTemplates.map((videoTemplate, index) => (
                        <option value={videoTemplate?.Id} key={index}>
                          {videoTemplate?.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-auto">{viewVideoTemplateBTN(videoId)}</div>
                  {isLoadVideoTemplates && <FullLoader width="0.75rem" />}
                </div>
              </div>
               }
              <div className="row">
                <div className="col text-center">
                  <button
                    disabled={
                      !companyName?.trim() ||
                      !ValidateEmail(email) ||
                      !phoneNumber?.trim() ||
                      phoneNumber.includes('_') ||
                      /[^A-Za-z0-9]/.test(customDomainName[0]) ||
                      /[^A-Za-z0-9]/.test(customDomainName[customDomainName.length - 1]) ||
                      !city?.trim() ||
                      isLoadingCustomDomain ||
                      !!customDomainWarnings?.trim()
                    }
                    onClick={editSiteAndRefresh}
                    style={{
                      color: '#fff',
                      backgroundColor: 'rgb(45, 206, 227)',
                      borderColor: 'rgb(34, 194, 220)',
                    }}
                    className="btn m-2"
                  >
                    Edit Site
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      {/*Delete Site*/}

      <Modal title={'Delete site'} visibility={isSiteDeleteModal} closeFunc={SiteModalsClose}>
        <div style={{ position: 'relative' }}>
          <h5 className="text-center">Are you sure you want to delete this site?</h5>
          <div className="row">
            <div className="col text-center">
              <button
                onClick={deleteAndRefresh}
                style={{
                  color: '#fff',
                  backgroundColor: 'rgb(255, 117, 136)',
                  borderColor: 'rgb(255, 98, 117)',
                }}
                className="btn m-2"
              >
                Delete Site
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AllSites;
