import { toast } from 'react-toastify';
import { authFetch, updateFetch } from '../myFetches';

const AllSitesRequests = {
  getAllSitesByPage: (
    SearchString: string | undefined,
    SortOrder: string | undefined,
    SortType: string | undefined,
    PageNumber: number,
    NumberOfRecords: number,
    statusCodeHandle?: (status: number) => {}
  ) =>
    authFetch(window.location.origin + '/api/admin/allSites/getAllSitesByPage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        SearchString: SearchString,
        SortOrder: SortOrder,
        SortType: SortType,
        PageNumber: PageNumber,
        NumberOfRecord: NumberOfRecords,
      }),
    }).then(async response => {
      if (statusCodeHandle) {
        statusCodeHandle(response.status);
      }
      if (response.status !== 200) {
        return;
      }
      try {
        const result = await response.json();
        if (
          result.isSuccessful &&
          result.data &&
          'WordpressSites' in result.data &&
          'TotalSitesForUser' in result.data
        ) {
          return result.data;
        } else if (result.errors.length > 0) {
          result.errors.forEach((error: string) => toast.error(error));
          return [];
        } else {
          return [];
        }
      } catch (e) {
        console.log('Error. getNumberOfSites: ' + e);
        toast.error('Unexpected error');
      }
    }),
  updateSiteById: (
    id: string | number,
    url:string,
    cb: () => {},
    statusCodeHandle?: (status: number) => {}
  ) =>
    updateFetch({
      link: window.location.origin + '/api/ManagerSSL/updateSslBySiteId',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(id),
      },
      toastTitle: `SSL certificate is updating on site with URL ${url}`,
      toastSuccess: 'SSL certificate updating was updated',
      callback: cb,
      statusCodeHandle,
    }),
  updateSiteByInstanceId: (
    InstanceId: string | number,
    ip:string,
    cb: () => {},
    statusCodeHandle?: (status: number) => {}
  ) =>
    updateFetch({
      link: window.location.origin + '/api/ManagerSSL/updateSslByInstanceId',
      options: {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(InstanceId),
      },
      toastTitle: `SSL certificates are updating on instance with ip ${ip}`,
      toastSuccess: 'SSL certificate updating were updated',
      callback: cb,
      statusCodeHandle,
    }),
};

export default AllSitesRequests;
