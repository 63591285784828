import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../types/account';
import { NotificationState } from '../../types/notification';
import './Alert.css';
const Alert: React.FC<NotificationState> = ({ type, paragraph, title, description }) => {
  return (
    <>
      {paragraph && (paragraph?.title || paragraph?.content) && (
        <div className={`Alert --${type}`}>
          <div>
            <h2>{title}</h2>
            <hr />
            {description}
          </div>
          <div>
            <div>{paragraph?.title}</div>
            <div>{paragraph?.content}</div>
          </div>
        </div>
      )}
    </>
  );
};

const CAlert = connect((state: RootState) => ({
  type: state.alert.type,
  title: state.alert.title,
  paragraph: state.alert.paragraph,
  description: state.alert.description,
}))(Alert as any);

export default CAlert;
