import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Header } from './Header/Header';
import { connect, ConnectedProps } from 'react-redux';
import React from 'react';
import { RootState } from '../types/account';

import accountThunks from '../app/thunks/accountThunks';
import Navbar from './Navbar/Navbar';
import Login from './Login';
import CAlert from './Alert/Alert';
import CContentArea from './ContentArea/ContentArea';

const connector = connect((state: RootState) => state, {
  ...accountThunks,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type MasterProps = PropsFromRedux & RouteComponentProps<any>;

//https://localhost:44307/Home/index

class Master extends React.PureComponent<MasterProps> {
  constructor(props: MasterProps | Readonly<MasterProps>) {
    super(props);
    this.handleStatusCode = this.handleStatusCode.bind(this);
    if (window.location.pathname.startsWith('/sitesmanagement') && window.location.search.startsWith('?jwt_invite=')) {
      const token = window.location.search.split('?jwt_invite=')[1];
      this.props.redirectedLogin(token, this.handleStatusCode);
    }
  }
 
  handleStatusCode(status: number) {
    if (status === 401) {
      this.props.logout();
    }
    return status;
  }

  public render() {
    console.log(this.props.account.account);
    return (
      <>
        {this.props.account.isAuthenticated ? (
          <>
          
            <Header
              isAuthenticated={this.props.account.isAuthenticated}
              username={this.props.account.account.username}
              logout={this.props.logout}
            />
            <Navbar account={this.props.account} />
            <div id="wrapper">
              {/* <Sidebar account={this.props.account} logout = {this.props.logout} user = {"user"} /> */}

              <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                  <div className="AlertContainer">
                    <CAlert />
                  </div>
                  <CContentArea handleStatusCode={this.handleStatusCode} account={this.props.account} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <Login />
        )}
      </>
    );
  }
}

export default withRouter(Master);
