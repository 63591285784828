const TableCellStyle = {
  minWidth: 'max-content',
  border: '1px solid #e3ebf3',
  padding: '4px 17px 4px 4px',
  maxWidth: '20%',
};

const TableHeaderStyle = {
  minWidth: 'max-content',
  border: '1px solid #e3ebf3',
  borderBottom: '2px solid #e3ebf3',
  padding: '4px 17px 4px 4px',
};

const pageSizeList = [10, 25, 50, 100];

const TableOptions = {
  searchFieldVariant: 'outlined',
  maxBodyHeight: 650,
  initialPage: 0,
  paging: true,
  sorting: true,
  emptyRowsWhenPaging: false,
  paginationType: 'stepped',
  pageSize: 10,
  pageSizeOptions: pageSizeList,
  showFirstLastPageButtons: true,
  thirdSortClick: false,
  headerStyle: {
    border: '1px solid #e3ebf3',
  },
};

const ValidateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export { TableCellStyle, TableHeaderStyle, ValidateEmail, TableOptions };
