import { Switch, Route, useLocation } from 'react-router-dom';
import AwsMonitor from '../../Pages/AwsMonitor/AwsMonitor';

import MySites from '../../Pages/MySites/MySites';
import WPServices from '../../Pages/WPServices/WPServices';
import { SetErrorAlert } from '../../app/actionCreators/alertActionCreators';
import { connect, ConnectedProps } from 'react-redux';
import { useEffect, useState } from 'react';
import { AccountState } from '../../types/account';
import './ContentArea.css';
import AllSites from '../../Pages/AllSites/AllSites';
import { Roles } from '../../types/users';
interface ContentAreaManualProps {
  account: AccountState;
  handleStatusCode?: (status: number) => {};
}

const connector = connect(null, { SetErrorAlert: SetErrorAlert });
type PropsFromRedux = ConnectedProps<typeof connector>;

type ContentAreaProps = ContentAreaManualProps & PropsFromRedux;

const ContentArea = function ({ handleStatusCode, account, SetErrorAlert }: ContentAreaProps) {
  const location = useLocation();

  

  const adminRoutes = [
    {
      path: '/aws-monitor',
      component: <AwsMonitor handleStatusCode={handleStatusCode} />,
    },
    {
      path: '/wp-services',
      component: <WPServices handleStatusCode={handleStatusCode} />,
    },
    {
      path: '/all-sites-management',
      component: <AllSites handleStatusCode={handleStatusCode} />,
    },
  ];

  const [full, setFull] = useState<boolean>(false);

  useEffect(() => {
    if (account.account.role.toUpperCase() === Roles.ADMIN) {
      setFull(location.pathname === '/errors-log');
    }
  }, [location, account.account.role]);

  return (
    <div className={`Area ${full ? '--full' : ''} container-fluid`}>
      <Switch>
        <Route exact path="/sitesmanagement">
          <MySites handleStatusCode={handleStatusCode} />
        </Route>

        <Route path="/" exact>
          <MySites handleStatusCode={handleStatusCode} />
        </Route>

        {account.account.role.toUpperCase() === Roles.ADMIN &&
          adminRoutes.map((route, index) => (
            <Route exact path={route.path} key={index}>
              {route.component}
            </Route>
          ))}
        <Route path="/*">
          <div>
            <div>
              <h1>404 NOT FOUND</h1>
            </div>
            <p>Page not found</p>
          </div>
        </Route>
      </Switch>
    </div>
  );
};
const CContentArea = connector(ContentArea);
export default CContentArea;
