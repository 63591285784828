import { AppThunkAction } from '../../types/account';
import { NotificationActions } from '../actions/notificationActions';

export function SetErrorAlert(
  message: { title: string; content: any },
  title = 'Error',
  description = ''
): AppThunkAction<NotificationActions> {
  return dispatch => {
    dispatch({ type: 'notify/setTitle', title });
    dispatch({ type: 'notify/setType', notificationType: 'error' });
    dispatch({ type: 'notify/setDescription', description });
    dispatch({ type: 'notify/addParagraph', paragraph: message });
  };
}
