import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './layout-style.css';
import Master from './components/Master';
import React from 'react';
import { AccountState, RootState } from './types/account';
import accountThunks, { AccountThunks } from './app/thunks/accountThunks';

import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationState } from './types/notification';
type AppProps = {
  account: AccountState;
  accountActions: AccountThunks;
  notify: NotificationState;
};

class App extends React.PureComponent<AppProps> {
  public render() {
    return (
      <>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable={false}
        />

        <Master
          account={this.props.account}
          login={this.props.accountActions.login}
          logout={this.props.accountActions.logout}
          alert={this.props.notify}
          redirectedLogin={this.props.accountActions.redirectedLogin}
        />
      </>
    );
  }
}

export default connect(
  (state: RootState) => {
    return {
      account: state.account,
      notify: state.alert,
    };
  },

  {
    ...accountThunks,
  },

  (a, b) => {
    const cloneAndPluck = (sourceObject: any, filterObject: any) => {
      const newObject: { [key: string]: any } = {};
      for (const key in filterObject) newObject[key.toString()] = sourceObject[key];
      return newObject;
    };
    return {
      ...a,
      accountActions: cloneAndPluck(b, accountThunks),
    };
  }
)(App as any);
