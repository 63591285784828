import { Reducer } from 'redux';
import { AccountActions } from '../actions/accountActions';
import { AccountState } from '../../types/account';

const initialState: AccountState = {
  isAuthenticated: false,
  account: {
    id: '',
    email: '',
    username: '',
    role: '',
    roles: [],
    hasNoInfusionSoftID: '',
  },
};

const accountReducer: Reducer<AccountState, any> = (
  currentState: AccountState | undefined,
  incomingAction: AccountActions
): AccountState => {
  if (currentState === undefined && localStorage.creimsToken) {
    const expireDate = new Date(JSON.parse(atob(localStorage.creimsToken.split('.')[1])).exp * 1000);
    if (expireDate < new Date()) {
      incomingAction.type = 'user/logout';
    } else {
      incomingAction.type = 'user/login-success';
    }
  }

  if (incomingAction.type === 'user/login-success') {
    if (incomingAction.token) {
      localStorage.creimsToken = incomingAction.token;
    }
    if (incomingAction.roles) {
      localStorage.creimsRoles = incomingAction.roles;
    }
    try {
      const user = JSON.parse(atob(localStorage.creimsToken.split('.')[1]));
      const roles = localStorage.creimsRoles ? localStorage.creimsRoles.split(',') : [];
      return {
        ...currentState,
        isAuthenticated: true,
        account: {
          id: user.id,
          email: user.email,
          username: user.name,
          role: user.role,
          hasNoInfusionSoftID: user.hasNoInfusionSoftID,
          roles,
        },
      };
    } catch (e) {
      incomingAction.type = 'user/logout';
    }
  }

  if (incomingAction.type === 'user/logout') {
    localStorage.removeItem('creimsToken');
    return {
      ...currentState,
      isAuthenticated: false,
      account: {
        id: '',
        email: '',
        username: '',
        role: '',
        hasNoInfusionSoftID: '',
        roles: [],
      },
    };
  }

  return currentState || initialState;
};

export default accountReducer;
